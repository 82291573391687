import { RestService } from "./RestService";

export class EVoucherBatchRestService extends RestService {
  constructor() {
    super("api/v1/evoucher_batches");
  }

  download(batchId) {
    return this.getImpl(`${batchId}/download`);
  }
}
