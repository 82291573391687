import React from 'react';
import _ from 'lodash'

export const ConfirmDialog = (props) => {
    const { message, title, visible, onProceed, onCancel, id } = props;

    if(!_.isNil(visible) && !visible) {
        return null;
    }
    return (
      <>
        <div
          className={`modal fade ${id ? "" : "show"}`}
          tabIndex="-1"
          role="dialog"
          id={id}
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
          style={id ? {} : { display: "block" }}
        >
          <div
            className="modal-dialog modal-md modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  {title || "Confirmation"}
                </h5>
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  data-bs-dismiss="modal"
                  onClick={onCancel}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {message || "Are you sure to perform this action?"}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={onProceed}
                >
                  Confirm
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                  onClick={onCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
        {!id && <div className="modal-backdrop fade show" />}
      </>
    );
}
