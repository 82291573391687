import React from "react";
import RestClient from "../../../../common/RestClient";
import { Card } from "../../../common/Card";
import Validators from "../../../common/Validators";
import { EvoucherOrderMode } from "../../common/EvoucherOrderMode";
import {
  StringHelper,
  preventNonNumericInput,
} from "../../../../common/Helpers";
import {
  EVoucherBatchStatus,
  EvoucherOrderStatus,
  StatusColor,
} from "../../../../common/Constants";
import { EvoucherProgramPreview } from "../../common/EvoucherProgramPreview";
import { EvoucherQuantityPreview } from "../../common/EvoucherQuantityPreview";
import { RowFormField } from "../../../layout/FormLayout";

export class EvoucherOrderCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardMode: EvoucherOrderMode.View,
      isDirty: false,
      model: this.mergeDefaultValue(props.model),
      evoucherPrograms: [],
      cachedEvoucherProgramDetails: [],
      validations: [],
      selectEvoucherProgramId: "",
    };
  }

  componentDidMount() {
    this.loadEvoucherPrograms();
    if (this.props.cardMode) {
      this.setState({ cardMode: this.props.cardMode });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.cardMode !== prevState.cardMode &&
      this.state.cardMode === EvoucherOrderMode.Update
    ) {
      this.setState({
        selectEvoucherProgramId: this.state.model.eVoucherProgram.id,
      });
      this.loadEvoucherProgramDetails(this.state.model.eVoucherProgram.id);
    }
    if (prevProps.isClear !== this.props.isClear) {
      this.setState({
        model: this.mergeDefaultValue(this.props.model),
        selectEvoucherProgramId: "",
      });
    }
    if (
      !this.refreshCache ||
      !this.state.model.evoucherProgram ||
      StringHelper.isNullOrEmpty(this.state.model.eVoucherProgram.id)
    ) {
      return;
    }
    this.refreshCache = false;
    this.loadEvoucherProgramDetails(this.state.model.eVoucherProgram.id);
  }

  loadEvoucherPrograms() {
    RestClient.sendGetRequest(
      "/api/v1/evoucher_orders/evoucher_programs",
      (response) => {
        this.setState({ evoucherPrograms: response });
      }
    );
  }

  loadEvoucherProgramDetails(evoucherProgramId) {
    RestClient.sendGetRequest(
      `/api/v1/evoucher_programs/${evoucherProgramId}`,
      (response) => {
        let { cachedEvoucherProgramDetails } = this.state;
        if (
          cachedEvoucherProgramDetails.findIndex(
            (prog) => prog.id === evoucherProgramId
          ) === -1
        ) {
          cachedEvoucherProgramDetails.push(response);
        }
        const newModel = Object.assign({}, this.state.model, {
          eVoucherProgram: response,
        });
        this.setState({ model: newModel }, this.onModelChange);
        this.setState({ cachedEvoucherProgramDetails });
      }
    );
  }

  isCachedEvoucherProgram(id) {
    return (
      this.state.cachedEvoucherProgramDetails.findIndex(
        (prog) => prog.id === id
      ) > -1
    );
  }

  getControlClassName(fieldName, defaultClass) {
    const isError =
      this.state.validations.some(
        (val) => val.fieldName === fieldName && !val.isValid
      ) &&
      this.props.isSubmit &&
      EvoucherOrderMode.isEditMode(this.state.evoucherOrderMode);
    return `form-control ${isError ? "is-invalid" : ""} ${defaultClass || ""}`;
  }

  getEvoucherProgram(id) {
    const program = this.state.cachedEvoucherProgramDetails.find(
      (prog) => prog.id === id
    );
    return (
      program || {
        name: "",
        shortName: "",
        costAmount: null,
        validityDuration: "",
        prefix: "",
        imageUrl: "",
      }
    );
  }

  mergeDefaultValue(defaultValue) {
    const value = defaultValue || {};
    return {
      eVoucherProgram: value.eVoucherProgram || { id: "" },
      id: value.id || 0,
      orderId: value.reference || "",
      companyName: value.companyName || "",
      memoNumber: value.memoNumber || "",
      purchaseOrderNumber: value.purchaseOrderNumber || "",
      totalQuantity: value.totalQuantity || 0,
      pendingQuantity: value.pendingQuantity || 0,
      totalCost: value.totalCost || 0.0,
      paymentTerms: value.paymentTerms || "",
      remarks: value.remarks || "",
      createdBy: value.createdBy || "",
      createdOn: value.createdOn || "",
      issuedQuantity: value.issuedQuantity || 0,
      referenceCode: value.referenceCode || "",
      status: value.status,
      reason: value.reason || "",
      eVoucherBatches: value.eVoucherBatches || [],
    };
  }

  shouldComponentUpdate(nextProps) {
    let newState = null;
    if (this.props.defaultValue !== nextProps.defaultValue) {
      const model = this.mergeDefaultValue(nextProps.defaultValue);
      this.refreshCache = true;
      newState = Object.assign(newState || {}, {
        isDirty: false,
        model,
      });
    }
    if (this.props.cardMode !== nextProps.cardMode) {
      newState = Object.assign(newState || {}, {
        cardMode: nextProps.cardMode,
      });
    }
    if (newState) {
      this.setState(newState, this.onModelChange);
    }
    return newState === null;
  }

  onEvoucherProgramChange = (e) => {
    this.onTextBoxChange(e);
    const evoucherProgramId = e.target.value;
    if (StringHelper.isNullOrEmpty(evoucherProgramId)) {
      return;
    }
    if (!this.isCachedEvoucherProgram(evoucherProgramId)) {
      this.loadEvoucherProgramDetails([evoucherProgramId]);
    }
    this.setState({ selectEvoucherProgramId: e.target.value });
  };

  onModelChange() {
    const { onModelChange } = this.props;
    if (onModelChange) {
      const model = Object.assign({}, this.state.model);
      onModelChange(model);
    }
  }

  onTextBoxChange = (e) => {
    const fieldName = e.target.getAttribute("fieldname");
    const value = e.target.value;
    const newModel = Object.assign({}, this.state.model, {
      [fieldName]: value,
    });
    this.setState({ isDirty: true, model: newModel }, this.onModelChange);
  };

  onTextBoxTotalQuantityChange = (e) => {
    const value = e.target.value;
    const evoucherProgramDetail = this.getEvoucherProgram(
      StringHelper.toNumber(this.state.selectEvoucherProgramId, 0)
    );
    const totalCostUpdated =
      StringHelper.toNumber(this.state.selectEvoucherProgramId, 0) !== 0
        ? evoucherProgramDetail.salesAmount * StringHelper.toNumber(value, 0)
        : 0;
    const newModel = Object.assign({}, this.state.model, {
      totalQuantity: value,
      totalCost: totalCostUpdated,
    });
    this.setState({ isDirty: true, model: newModel }, this.onModelChange);
  };

  onValidationsChange = () => {
    const { onValidationsChange } = this.props;
    if (onValidationsChange) {
      const validations = [...this.state.validations];
      onValidationsChange(validations);
    }
  };

  onValidationStatusChange = (e) => {
    let { validations } = this.state;
    let validation = validations.find(
      (val) => val.fieldName === e.fieldName && val.type === e.type
    );
    if (validation) {
      validation.isValid = e.isValid;
      validation.message = e.message;
    } else {
      validations.push(e);
    }
    this.setState({ validations }, this.onValidationsChange);
  };

  render() {
    const renderMethods = {
      [EvoucherOrderMode.View]: this.renderViewMode.bind(this),
      [EvoucherOrderMode.Create]: this.renderCreateMode.bind(this),
      [EvoucherOrderMode.Update]: this.renderCreateMode.bind(this),
    };
    return (
      <Card title="eVoucher Order Details">
        {renderMethods[this.state.cardMode]()}
      </Card>
    );
  }

  getStatusColor = (status) => {
    switch (status) {
      case EvoucherOrderStatus.Approved:
        return StatusColor.Green;
      case EvoucherOrderStatus.Pending:
        return StatusColor.Orange;
      case EvoucherOrderStatus.Rejected:
        return StatusColor.Red;
      case EvoucherOrderStatus.Cancelled:
        return StatusColor.Gray;
      default:
        return "";
    }
  };

  renderCreateMode() {
    const { isSubmit, defaultValue } = this.props;
    const { model, evoucherPrograms } = this.state;
    const {
      companyName,
      memoNumber,
      purchaseOrderNumber,
      totalQuantity,
      paymentTerms,
      remarks,
      referenceCode,
      totalCost,
      eVoucherProgram,
    } = model;
    const evoucherProgramDetail = this.getEvoucherProgram(
      StringHelper.toNumber(this.state.selectEvoucherProgramId, 0)
    );
    const hasSelectedEvoucherProgramId =
      StringHelper.toNumber(this.state.selectEvoucherProgramId, 0) !== 0;
    const isApproved =
      defaultValue && defaultValue.status === EvoucherOrderStatus.Approved;
    return (
      <>
        <div className="details row flex-column-reverse flex-md-row">
          <div className="col-md-8">
            <RowFormField
              label={
                <span>
                  eVoucher Program<span className="color-red">*</span>:
                </span>
              }
              htmlFor="EvoucherProgramId"
            >
              <select
                id="EvoucherProgramId"
                className={this.getControlClassName("evoucherProgramId")}
                fieldname="evoucherProgramId"
                onChange={this.onEvoucherProgramChange}
                value={eVoucherProgram.id}
                disabled={isApproved}
              >
                <option value="">Select an eVoucher Program</option>
                {evoucherPrograms &&
                  evoucherPrograms.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
              </select>
              <Validators.RequiredValidator
                onValidationStatusChange={this.onValidationStatusChange}
                fieldName="evoucherProgramId"
                isEnabled={isSubmit}
                property="eVoucher Program"
                value={this.state.selectEvoucherProgramId}
              />
              {hasSelectedEvoucherProgramId && (
                <div className="card mt-3 mb-0" id="hiddenCard">
                  <div className="card-body">
                    <div className="row form-group">
                      <label
                        className="form-control-label col-xl-5"
                        htmlFor="CostAmount"
                      >
                        Price:
                      </label>
                      <label
                        className="form-control-label col-xl-7"
                        id="CostAmount"
                      >
                        {evoucherProgramDetail.salesAmount?.toFixed(6)}
                      </label>
                    </div>
                    <div className="row form-group">
                      <label
                        className="form-control-label col-xl-5"
                        id="ValidityDuration"
                      >
                        Validity Duration:
                      </label>
                      <label
                        className="form-control-label col-xl-7"
                        id="ValidityDuration"
                      >{`${evoucherProgramDetail.validityDuration} days`}</label>
                    </div>
                    <div className="row form-group">
                      <label
                        className="form-control-label col-xl-5"
                        htmlFor="Prefix"
                      >
                        Prefix:
                      </label>
                      <label
                        className="form-control-label col-xl-7"
                        id="Prefix"
                      >
                        {evoucherProgramDetail.prefix}
                      </label>
                    </div>
                  </div>
                </div>
              )}
            </RowFormField>
            <RowFormField
              label={
                <span>
                  Company Name<span className="color-red">*</span>:
                </span>
              }
              htmlFor="CompanyName"
            >
              <input
                type="text"
                id="CompanyName"
                placeholder="Enter Company Name"
                className={this.getControlClassName("companyName")}
                fieldname="companyName"
                onChange={this.onTextBoxChange}
                value={companyName}
                disabled={isApproved}
              />
              <Validators.RequiredValidator
                onValidationStatusChange={this.onValidationStatusChange}
                fieldName="companyName"
                isEnabled={isSubmit}
                property="Company Name"
                value={companyName}
              />
            </RowFormField>
            <RowFormField
              label={
                <span>
                  Total Order Quantity<span className="color-red">*</span>:
                </span>
              }
              htmlFor="TotalQuantity"
            >
              <input
                type="number"
                id="TotalQuantity"
                placeholder="Enter Total Quantity"
                className={this.getControlClassName("totalQuantity")}
                fieldname="totalQuantity"
                onChange={this.onTextBoxTotalQuantityChange}
                value={totalQuantity}
                disabled={isApproved}
                onKeyDown={preventNonNumericInput}
              />
              <Validators.RangeValidator
                onValidationStatusChange={this.onValidationStatusChange}
                fieldName="totalQuantity"
                isEnabled={isSubmit}
                min="1"
                max="100000"
                property="Total Quantity"
                value={totalQuantity}
                allowDecimal={false}
              />
            </RowFormField>
            <RowFormField label="Total Cost:" htmlFor="TotalCost">
              <input
                type="number"
                id="TotalCost"
                placeholder="Enter Total Cost"
                className={this.getControlClassName("totalCost")}
                fieldname="totalCost"
                value={totalCost}
                readOnly={true}
              />
              <Validators.RequiredValidator
                onValidationStatusChange={this.onValidationStatusChange}
                fieldName="totalCost"
                isEnabled={isSubmit}
                property="Total Total Cost"
                value={totalCost}
              />
            </RowFormField>
            <RowFormField
              label={
                <span>
                  Order Reference Code<span className="color-red">*</span>:
                </span>
              }
              htmlFor="ReferenceCode"
            >
              <input
                type="text"
                id="ReferenceCode"
                placeholder="Enter Reference Code"
                className={this.getControlClassName("referenceCode")}
                fieldname="referenceCode"
                onChange={this.onTextBoxChange}
                value={referenceCode}
                disabled={isApproved}
              />
              <Validators.RequiredValidator
                onValidationStatusChange={this.onValidationStatusChange}
                fieldName="referenceCode"
                isEnabled={isSubmit}
                property="Reference Code"
                value={referenceCode}
              />
            </RowFormField>
            <RowFormField
              label="Purchase Order Number:"
              htmlFor="PurchaseOrderNumber"
            >
              <input
                type="text"
                id="PurchaseOrderNumber"
                placeholder="Enter Purchase Order Number"
                className={this.getControlClassName("purchaseOrderNumber")}
                fieldname="purchaseOrderNumber"
                onChange={this.onTextBoxChange}
                value={purchaseOrderNumber}
              />
            </RowFormField>
            <RowFormField label="Memo No.:" htmlFor="MemoNumber">
              <input
                type="text"
                id="MemoNumber"
                placeholder="Enter Memo Number"
                className={this.getControlClassName("memoNumber")}
                fieldname="memoNumber"
                onChange={this.onTextBoxChange}
                value={memoNumber}
              />
            </RowFormField>
            <RowFormField label="Payment Terms:" htmlFor="PaymentTerms">
              <textarea
                id="PaymentTerms"
                rows="4"
                placeholder="Enter Payment Terms"
                className={this.getControlClassName("paymentTerms")}
                fieldname="paymentTerms"
                onChange={this.onTextBoxChange}
                value={paymentTerms}
              />
              <Validators.MaxLengthValidator
                onValidationStatusChange={this.onValidationStatusChange}
                fieldName="paymentTerms"
                isEnabled={isSubmit}
                maxLength="250"
                property="Payment Terms"
                value={paymentTerms}
              />
            </RowFormField>
            <RowFormField label="Remarks:" htmlFor="Remarks">
              <textarea
                id="Remarks"
                rows="4"
                placeholder="Enter Remarks"
                className={this.getControlClassName("remarks")}
                fieldname="remarks"
                onChange={this.onTextBoxChange}
                value={remarks}
              />
            </RowFormField>
          </div>
          {hasSelectedEvoucherProgramId && (
            <div className="col-md-4 form-group">
              <img
                id="image"
                className="img-thumbnail"
                src={evoucherProgramDetail.imageUrl}
                alt="eVoucher"
              />
            </div>
          )}
        </div>
      </>
    );
  }

  renderViewMode() {
    const { model } = this.state;
    const {
      orderId,
      companyName,
      memoNumber,
      purchaseOrderNumber,
      totalQuantity,
      issuedQuantity,
      pendingQuantity,
      totalCost,
      paymentTerms,
      remarks,
      eVoucherProgram,
      status,
      referenceCode,
      createdBy,
      createdOn,
      eVoucherBatches,
    } = model;
    const titles = {
      titleColumn1: "Total Order Quantity",
      titleColumn2: "Issued & Pending Quantity",
    };
    const quantities = {
      quantityColumn1: totalQuantity,
      quantityColumn2: issuedQuantity + pendingQuantity,
    };

    const numOfApprovedBatches = eVoucherBatches.filter(
      (x) => x.status === EVoucherBatchStatus.Approved
    ).length;
    const shouldDisplayNumOfBatches =
      status === EvoucherOrderStatus.Approved && numOfApprovedBatches > 0;
    return (
      <>
        <div className="details row flex-column-reverse flex-md-row">
          <div className="col-xl-7">
            <RowFormField label="Order ID:" htmlFor="OrderId">
              <input
                type="text"
                id="OrderId"
                className="form-control display-input"
                value={orderId}
                readOnly
              />
            </RowFormField>
            {eVoucherProgram && (
              <EvoucherProgramPreview evoucherProgramId={eVoucherProgram.id} />
            )}
            {shouldDisplayNumOfBatches && (
              <RowFormField
                label="Number of Issued Batches:"
                htmlFor="IssuedQuantity"
              >
                <input
                  type="text"
                  id="IssuedQuantity"
                  className="form-control display-input"
                  value={numOfApprovedBatches}
                  readOnly
                />
              </RowFormField>
            )}
            <RowFormField label="Company Name:" htmlFor="CompanyName">
              <input
                type="text"
                id="CompanyName"
                className="form-control display-input"
                value={companyName}
                readOnly
              />
            </RowFormField>
            <RowFormField label="Status:" htmlFor="Status">
              <input
                type="text"
                id="Status"
                className={this.getStatusColor(status)}
                value={EvoucherOrderStatus.getName(status)}
                readOnly
              />
            </RowFormField>
            <RowFormField label="Total Order Quantity:" htmlFor="TotalQuantity">
              <input
                type="number"
                id="TotalQuantity"
                className="form-control display-input"
                value={totalQuantity}
                readOnly
              />
            </RowFormField>
            <RowFormField label="Total Cost:" htmlFor="TotalCost">
              <input
                type="number"
                id="TotalCost"
                className="form-control display-input"
                value={totalCost}
                readOnly
              />
            </RowFormField>
            <RowFormField
              label="Order Reference Code:"
              htmlFor="OrderReferenceCode"
            >
              <input
                type="text"
                id="OrderReferenceCode"
                className="form-control display-input"
                value={referenceCode}
                readOnly
              />
            </RowFormField>
            <RowFormField
              label="Purchase Order Number:"
              htmlFor="PurchaseOrderNumber"
            >
              <input
                type="text"
                id="PurchaseOrderNumber"
                className="form-control display-input"
                value={purchaseOrderNumber}
                readOnly
              />
            </RowFormField>
            <RowFormField label="Memo No.:" htmlFor="MemoNumber">
              <input
                type="text"
                id="MemoNumber"
                className="form-control display-input"
                value={memoNumber}
                readOnly
              />
            </RowFormField>
            <RowFormField label="Created By:" htmlFor="CreatedBy">
              <input
                type="text"
                id="CreatedBy"
                className="form-control display-input"
                value={createdBy}
                readOnly
              />
            </RowFormField>
            <RowFormField label="Created On:" htmlFor="CreatedOn">
              <input
                type="text"
                id="CreatedOn"
                className="form-control display-input"
                value={StringHelper.asDateStringToDisplayDateTimeFormat(
                  createdOn
                )}
                readOnly
              />
            </RowFormField>
            <RowFormField label="Payment Terms:" htmlFor="PaymentTerms">
              <input
                type="text"
                id="PaymentTerms"
                className="form-control display-input"
                value={paymentTerms}
                readOnly
              />
            </RowFormField>
            <RowFormField label="Remarks:" htmlFor="Remarks">
              <input
                type="text"
                id="Remarks"
                className="form-control display-input"
                value={remarks}
                readOnly
              />
            </RowFormField>
          </div>
          {eVoucherProgram && (
            <EvoucherQuantityPreview
              imageLink={eVoucherProgram.urlImage}
              titles={titles}
              quantities={quantities}
            />
          )}
        </div>
      </>
    );
  }
}
