import React from "react";
import { DateHelper } from "../../../../common/Helpers";
import { EvoucherProgramPreview } from "../../common/EvoucherProgramPreview";
import { Card } from "../../../common/Card";
import { RowFormField } from "../../../layout/FormLayout";
import { EVoucherBatchRestService } from "../../../../services/EVoucherBatchRestService";
import { EVoucherService } from "../../../../services/EVoucherService";

export class EvoucherInformationCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { eVoucher: null, evoucherBatch: null };
    this.eVoucherBatchRestService = new EVoucherBatchRestService();
    this.eVoucherService = new EVoucherService();
  }

  async componentDidMount() {
    await this.loadEvoucher();
  }

  async componentDidUpdate(prevProps) {
    if (
      prevProps.evoucherId !== this.props.evoucherId ||
      prevProps.isApprove !== this.props.isApprove
    ) {
      await this.loadEvoucher();
    }
  }

  async loadEvoucher() {
    const { evoucherId } = this.props;
    const response = await this.eVoucherService.get(evoucherId);
    if (response.status === 200) {
      const eVoucher = response.data;
      this.setState({ eVoucher });
      await this.loadEVoucherBatchById(eVoucher.eVoucherBatchId);
    }
  }

  loadEVoucherBatchById = async (eVoucherBatchId) => {
    const response = await this.eVoucherBatchRestService.get(eVoucherBatchId);
    if (response.status === 200) {
      this.setState({ evoucherBatch: response.data });
    }
  };

  renderView(eVoucher, evoucherBatch) {
    return (
      <div className="row">
        <div className="col-md-8">
          <RowFormField label="eVoucher Code:" htmlFor="Code">
            <input
              className="form-control display-input"
              type="text"
              id="Code"
              style={{ border: "none" }}
              defaultValue={eVoucher.code}
              readOnly
            />
          </RowFormField>
          <EvoucherProgramPreview
            evoucherProgramId={eVoucher.eVoucherProgram?.id}
          />
          <RowFormField label="Batch ID:" htmlFor="BatchId">
            <input
              className="form-control display-input"
              type="text"
              id="BatchId"
              value={evoucherBatch?.reference}
              readOnly
            />
          </RowFormField>
          <RowFormField label="Validity Start:" htmlFor="StartDate">
            <input
              className="form-control display-input"
              type="text"
              id="StartDate"
              value={DateHelper.toDisplayDateFormat(eVoucher.validityStart)}
              readOnly
            />
          </RowFormField>
          <RowFormField label="Expiry Date:" htmlFor="ValidityEnd">
            <input
              className="form-control display-input"
              type="text"
              id="ValidityEnd"
              value={DateHelper.toDisplayDateFormat(eVoucher.validityEnd)}
              readOnly
            />
          </RowFormField>
          <RowFormField label="Remaining Duration:" htmlFor="RemainingDuration">
            <input
              className="form-control display-input activated used"
              type="text"
              id="RemainingDuration"
              value={`${Math.ceil(eVoucher.remainingDuration)} days`}
              readOnly
            />
          </RowFormField>
          <div
            className="row form-group"
            id="approvalOnly"
            style={{ display: "none" }}
          >
            <div className="col-md-3">
              <label className="form-control-label" htmlFor="program">
                Number Of Issued Batches:
              </label>
            </div>
            <div className="col-md-9">
              <input
                className="form-control display-input"
                type="number"
                value="3"
                readOnly
              />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <img
            id="image"
            className="img-thumbnail ml-auto"
            src={eVoucher.eVoucherProgram?.urlImage}
            alt="Voucher"
          />
        </div>
      </div>
    );
  }

  render() {
    const { eVoucher, evoucherBatch } = this.state;
    return (
      eVoucher && (
        <Card title="eVoucher Information">
          {this.renderView(eVoucher, evoucherBatch)}
        </Card>
      )
    );
  }
}
