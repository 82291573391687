import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BreadCrumb } from "../../common/BreadCrumb";
import { DateHelper } from "../../../common/Helpers";
import _ from "lodash";
import RestClient from "../../../common/RestClient";
import { SeatPlanComponent } from "./seat.selection/SeatPlanComponent";
import { BalestierDreamerSeatPlan } from "./seat.selection/BalestierDreamerSeatPlan";
import {
  CorporateBookingCouponDetails,
  CorporateBookingModel,
} from "./models/CorporateBookingCreationRequest";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { ClientSelectionModal } from "./modals/ClientSelectionModal";
import { NewClientModal } from "./modals/NewClientModal";
import {
  CorporateBookingStatus,
  CorporateBookingType,
  CouponType,
  PaymentOption,
  TicketDistributionType,
  TicketStatus,
} from "../../../constants/CorporateBookingConstants";
import { Classify, LocationBrand } from "../../../constants/MovieConstants";
import { Mode } from "../../../constants/Mode";
import { Link, useParams } from "react-router-dom";
import { WebServiceClient } from "./WebServiceClient";
import { HistoryDialog } from "../../common/HistoryDialog";
import { ObjectType } from "../../../common/Constants";
import { Card } from "../../common/Card";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";
import { FieldWrapper } from "../../campaigns/FieldWrapper";
import { CustomSelect } from "../../common/CustomSelect";
import { useCampaigns } from "../useCampaigns";
import { useServices } from "../../../hooks/useServices";
import { usePerformance } from "../usePerformance";
import { INVALID_SEAT } from "../../../common/ApiErrorCode";
import { PerformanceTypes } from "../../../constants/PricingConstants";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";
import { CompanyType } from "../../../constants/CompanyType";
import {
  AuthenticationManager,
  ResourceID,
} from "../../../common/AuthenticationManager";
import { useLayoutLiveLives } from "../useLayoutLiveLives";

export const CorporateBookingDetails = () => {
  const [instance, setInstance] = useState(new CorporateBookingModel({}));
  const [preSelectedSeat, setPreSelectedSeat] = useState([]);
  
  const [mode, setMode] = useState(Mode.Readonly);
  const [client, setClient] = useState(null);
  const [showCoupon, setShowCoupon] = useState(false);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [showSearchClient, setShowSearchClient] = useState(false);
  const [showNewClient, setShowNewClient] = useState(false);
  const [showSaveConfirmation, setShowSaveConfirmation] = useState(false);
  const [showCancelCorporateConfirmation, setShowCancelCorporateConfirmation] =
    useState(false);
  const [
    showConfirmCorporateConfirmation,
    setShowConfirmCorporateConfirmation,
  ] = useState(false);
  const [showHistoryDialog, setShowHistoryDialog] = useState(false);
  const [reloadSeatSelection, setReloadSeatSelection] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [campaignBundles, setCampaignBundles] = useState([]);

  const { campaigns, campaignOptions } = useCampaigns();
  const { services } = useServices(PerformanceTypes.SpecialScreening);
  const { performance, location, locationVenue } = usePerformance(
    instance.performanceId
  );

  const { id } = useParams();
  const { remainingCapacity } = useLayoutLiveLives(instance.performanceId);

  const webServiceClient = useMemo(() => new WebServiceClient(), []);
  webServiceClient.onSuccess(() => {
    toast.success("The print message has been sent successful.");
  });

  const totalRemainingCapacity = remainingCapacity + preSelectedSeat.length;

  const getNavigationSettings = () => {
    const name =
      mode === Mode.Editable
        ? "Edit Corporate Bookings"
        : "View Corporate Booking Details";
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "corporate_bookings",
        name: "Corporate Bookings",
      }),
      activeModule: new NavigationItem({
        identifier: "corporate_bookings",
        name: name,
      }),
    });
  };

  const onSelectClient = (client) => {
    setClient(client);
    onCloseAllModals();
  };

  const onCloseAllModals = () => {
    setShowCancelCorporateConfirmation(false);
    setShowConfirmCorporateConfirmation(false);
    setShowCancelConfirmation(false);
    setShowSaveConfirmation(false);
    setShowSearchClient(false);
    setShowNewClient(false);
  };

  const onCancelBooking = () => {
    RestClient.sendPostRequest(
      `/api/v1/corporate_bookings/${id}/cancel`,
      {},
      (response) => {
        toast.success("The corporate booking has been cancelled successful.");
        setInstance(new CorporateBookingModel(response));
      },
      handleError
    );
    onCloseAllModals();
  };

  const onPrintSample = () => {
    RestClient.sendGetRequestWithParameters(
      `/api/v1/corporate_booking_print_and_distribute_tickets/print_sample`,
      { corporateBookingId: id },
      (response) => {
        webServiceClient.sendRequest(response);
      },
      handleError
    );
    onCloseAllModals();
  };

  const onConfirmBooking = () => {
    if (!isValidConfirmBooking()) {
      return;
    }

    RestClient.sendPostRequest(
      `/api/v1/corporate_bookings/${id}/confirm`,
      {},
      (response) => {
        toast.success("The corporate booking has been confirmed successful.");
        setInstance(new CorporateBookingModel(response));
      },
      handleError
    );
    onCloseAllModals();
  };

  const isValidConfirmBooking = () => {
    const {
      corporateBookingDetails: { seatCodes, guestCount },
    } = instance;

    const selectedSeatCodes = seatCodes.length;

    if (selectedSeatCodes <= 0) {
      toast.error(
        `No seats are selected. Please select ${guestCount} seat(s) before confirming.`
      );
      onCloseAllModals();
      return false;
    }
    if (selectedSeatCodes < guestCount) {
      toast.error(
        `Please select more ${
          guestCount - selectedSeatCodes
        } seat(s) before confirming.`
      );
      onCloseAllModals();
      return false;
    }
    return true;
  };

  const handleError = (error) => {
    console.error(error);
    if (error.code === INVALID_SEAT) {
      toast.error(
        "There are some selected seats that were hold by others. Please refresh the page and select again."
      );
      return;
    }
    toast.error(error.message);
  };

  const onInputChange = (event) => {
    const fieldName = event.target.getAttribute("name");
    const inputType = event.target.getAttribute("type");
    const valueType = event.target.getAttribute("valuetype");
    const cloned = { ...instance };
    let selectValue = event.target.value;
    if (_.isEqual("number", valueType) && !isNaN(+selectValue)) {
      selectValue = +selectValue;
    }

    if (_.isEqual("boolean", valueType)) {
      selectValue = event.target.checked;
    }

    if (_.isEqual("radio", inputType)) {
      selectValue = selectValue === "true";
    }

    if (fieldName.indexOf(".") > 0) {
      const fields = fieldName.split(".");
      const object = fields[0];
      const field = fields[1];
      cloned[object][field] = selectValue;
    } else {
      cloned[fieldName] = selectValue;
    }
    setInstance(cloned);
  };

  const onSaveChanges = () => {
    if (isNaN(+id)) {
      return;
    }
    const body = {
      ...instance,
      corporateBookingCompanyId: client.id,
    };
    RestClient.sendPutRequest(
      `api/v1/corporate_bookings/${+id}`,
      body,
      (response) => {
        setInstance(new CorporateBookingModel(response));
        toast.success("The corporate booking has been save successful.");
        setMode(Mode.Readonly);
      },
      handleError
    );
    onCloseAllModals();
  };

  const onChangeSeatCodes = (event) => {
    const clonedInstance = { ...instance };
    clonedInstance.corporateBookingDetails.seatCodes = (
      event.selectedSeats ?? []
    ).map((x) => x.seatCode);
    setInstance(clonedInstance);
  };

  const getInputCssClass = () => {
    if (mode === Mode.Readonly) {
      return "form-control display-input";
    }
    return "form-control";
  };

  const normalizeCampaignBundleOptions = (campaign) => {
    return (campaign?.campaignBundles ?? []).map((x) => ({
      id: x.id,
      displayName: `${x.id} - ${x.displayName}`,
    }));
  };

  const onSelectCampaign = (e) => {
    setInstance((data) => {
      return { ...data, campaignId: e.value };
    });
    const results = normalizeCampaignBundleOptions(
      campaigns.find((x) => x.id === e.value)
    );
    setCampaignBundles(results);
    if (results.length === 0) {
      setInstance((data) => {
        return { ...data, campaignBundleId: 0 };
      });
    }
  };

  const onSelectCampaignBundle = (e) => {
    setInstance((data) => {
      return { ...data, campaignBundleId: e.value };
    });
  };

  useEffect(() => {
    if (instance.corporateBookingCompanyId <= 0) {
      return;
    }
    RestClient.sendGetRequest(
      `api/v1/corporate_booking_companies/${instance.corporateBookingCompanyId}`,
      (client) => {
        setClient(client);
      },
      handleError
    );
  }, [instance.corporateBookingCompanyId]);

  useEffect(() => {
    RestClient.sendGetRequest(
      `api/v1/corporate_bookings/${id}`,
      (corporateBooking) => {
        const newInstance = new CorporateBookingModel(corporateBooking);
        setPreSelectedSeat(newInstance.corporateBookingDetails.seatCodes);
        setInstance(newInstance);
        setShowCoupon(!_.isEmpty(newInstance.couponDetails.couponName));
      },
      handleError
    );
  }, [id, refresh]);

  useEffect(() => {
    setCampaignBundles(
      normalizeCampaignBundleOptions(
        campaigns.find((x) => x.id === instance.campaignId)
      )
    );
  }, [campaigns, instance.campaignId]);

  const onCancelEdit = () => {
    setMode(Mode.Readonly);
    setShowCancelConfirmation(false);
    setReloadSeatSelection(true);
    setRefresh((r) => r + 1);
  };

  const getDisplayedCampaignBundle = useCallback(() => {
    const bundle = campaignBundles.find(
      (x) => x.id === instance.campaignBundleId
    );
    if (!bundle) {
      return "";
    }
    return `${bundle.displayName}`;
  }, [campaignBundles, instance.campaignBundleId]);

  const renderMovieSessionDetails = () => {
    const { ticketDetails, corporateBookingDetails } = instance;

    const isDisplayMovieAndLocation =
      !_.isNil(performance) && !_.isNil(location) && !_.isNil(locationVenue);
    return (
      <Card title="Movie Session Details">
        {isDisplayMovieAndLocation && (
          <>
            <div className="card my-0 showtimeCard">
              <div className="card-body my-0">
                <div className="row form-group my-0">
                  <div className="col-md-3">
                    <img
                      src={performance.posterUrl}
                      alt={performance.primaryTitle}
                    />
                  </div>
                  <div className="col-md-9">
                    <div className="row form-group">
                      <div className="col-md-4">
                        <label htmlFor="UserId" className="form-control-label">
                          Title:
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          className="form-control display-input"
                          id="Program"
                          type="text"
                          value={performance.primaryTitle}
                          readOnly={true}
                        />
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-md-4">
                        <label htmlFor="UserId" className="form-control-label">
                          Hall:
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          className="form-control display-input"
                          id="Program"
                          type="text"
                          value={`Shaw Theatres ${locationVenue.name}`}
                          readOnly={true}
                        />
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-md-4">
                        <label
                          htmlFor="UserId"
                          className="form-control-label"
                          style={{ width: "100%" }}
                        >
                          Date:
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          className="form-control display-input"
                          id="Program"
                          type="text"
                          value={DateHelper.toDisplayDateFormat(
                            performance.actualDateTime
                          )}
                          readOnly={true}
                        />
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-md-4">
                        <label htmlFor="UserId" className="form-control-label">
                          Time:
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          className="form-control display-input"
                          id="Program"
                          type="text"
                          value={DateHelper.toDisplayTimeFormat(
                            performance.actualDateTime
                          )}
                          readOnly={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mt-3 showtimeCard">
              <div className="card-body">
                <div className="row form-group my-0">
                  <div className="col-md-4">
                    <label className="form-control-label">
                      <i>Performance Service Code:</i>
                    </label>
                  </div>
                  <div className="col-md-8">
                    <input
                      className="form-control display-input"
                      type="text"
                      value={performance.service.code}
                      readOnly={true}
                    />
                  </div>
                </div>
                <div className="row form-group my-0">
                  <div className="col-md-4">
                    <label className="form-control-label">
                      <i>Description:</i>
                    </label>
                  </div>

                  <div className="col-md-8">
                    <input
                      className="form-control display-input"
                      type="text"
                      value={performance.service.name}
                      readOnly={true}
                    />
                  </div>
                </div>
                <div className="row form-group my-0">
                  <div className="col-md-4">
                    <label className="form-control-label">
                      <i>
                        Corporate Booking Service Code
                        <span className="color-red font-weight-bold">*</span>:
                      </i>
                    </label>
                  </div>
                  <div className="col-md-8">
                    {mode === Mode.Readonly && (
                      <input
                        className="form-control display-input"
                        type="text"
                        value={
                          corporateBookingDetails.corporateBookingServiceCode
                        }
                        readOnly={true}
                      />
                    )}
                    {mode === Mode.Editable && (
                      <select
                        className="form-control mb-0"
                        name="corporateBookingDetails.corporateBookingServiceCode"
                        value={
                          corporateBookingDetails.corporateBookingServiceCode
                        }
                        onChange={onInputChange}
                      >
                        <option value="">Select One</option>
                        {services.map((x) => {
                          return (
                            <option key={x.id} value={x.code}>
                              {x.code}
                            </option>
                          );
                        })}
                      </select>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="row form-group">
          <div className="col-md-4">
            <label className="form-control-label">Booking Reference:</label>
          </div>
          <div className="col-md-8">
            <input
              className="form-control display-input"
              value={instance.bookingReference}
              readOnly
            />
          </div>
        </div>
        <div className="row form-group">
          <label className="col-md-4 form-control-label text-nowrap">
            Booking Status:
          </label>
          <div className="col-md-8">
            <input
              className={`form-control display-input ${CorporateBookingStatus.getColor(
                instance.status
              )}`}
              value={CorporateBookingStatus.getNameStatus(instance.status)}
              readOnly
            />
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-4">
            <label className="form-control-label">
              Booking Type
              <span className="color-red font-weight-bold">*</span>:
            </label>
          </div>
          <div className="col-md-8">
            {mode === Mode.Readonly && (
              <input
                className="form-control display-input"
                value={CorporateBookingType.getName(
                  corporateBookingDetails.type
                )}
                readOnly
              />
            )}
            {mode === Mode.Editable && (
              <select
                className="form-control"
                name="corporateBookingDetails.type"
                valuetype="number"
                value={corporateBookingDetails.type}
                onChange={onInputChange}
              >
                <option value="">Select One</option>
                {CorporateBookingType.All.map((type, index) => (
                  <option value={type.value} key={index}>
                    {type.name}
                  </option>
                ))}
              </select>
            )}
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-4">
            <label className="form-control-label">
              Number of Guest
              <span className="color-red font-weight-bold">*</span>:
            </label>
          </div>
          <div className="col-md-6">
            <input
              className={getInputCssClass()}
              placeholder="Enter Number of Guest"
              type="number"
              min="1"
              max={totalRemainingCapacity}
              value={corporateBookingDetails.guestCount}
              valuetype="number"
              name="corporateBookingDetails.guestCount"
              onChange={onInputChange}
              readOnly={mode === Mode.Readonly}
            />
          </div>
          <div className="col-md-2">
            {mode === Mode.Editable && (
              <input
                className="form-control"
                value={totalRemainingCapacity}
                readOnly={true}
              />
            )}
          </div>
        </div>
        {instance.status === CorporateBookingStatus.Confirmed && (
          <div className="row form-group">
            <div className="col-md-4">
              <label className="form-control-label">Ticket Status:</label>
            </div>
            <div className="col-md-8">
              <input
                className={`form-control display-input ${TicketStatus.getColor(
                  corporateBookingDetails.ticketStatus
                )}`}
                value={TicketStatus.getName(
                  corporateBookingDetails.ticketStatus
                )}
                readOnly
              />
            </div>
          </div>
        )}
        <div className="row form-group">
          <div className="col-md-4">
            <label className="form-control-label text-nowrap">
              Mode of Ticket Distribution
              <span className="color-red font-weight-bold">*</span>:
            </label>
          </div>
          <div className="col-md-4">
            {mode === Mode.Readonly && (
              <input
                className="form-control display-input"
                value={TicketDistributionType.getName(
                  corporateBookingDetails.ticketDistribution
                )}
                readOnly
              />
            )}
            {mode === Mode.Editable && (
              <select
                className="form-control"
                disabled={_.isNil(performance)}
                value={corporateBookingDetails.ticketDistribution}
                valuetype="number"
                name="corporateBookingDetails.ticketDistribution"
                onChange={onInputChange}
              >
                <option value="">Select mode of ticket distribution</option>
                {TicketDistributionType.All.map((type, index) => (
                  <option value={type.value} key={index}>
                    {type.name}
                  </option>
                ))}
              </select>
            )}
          </div>
          <div className="col-md-4">
            <ButtonActionDetailGroup>
              {instance.status === CorporateBookingStatus.Confirmed && (
                <>
                  {(corporateBookingDetails.ticketDistribution ===
                    TicketDistributionType.Printed ||
                    corporateBookingDetails.ticketDistribution ===
                      TicketDistributionType.Mixed) && (
                    <ButtonActionDetailGroup.Item className="btn btn-primary">
                      <Link
                        className="text-white d-block"
                        to={`/corporate_bookings/${id}/print`}
                      >
                        Print Ticket
                      </Link>
                    </ButtonActionDetailGroup.Item>
                  )}
                  {(corporateBookingDetails.ticketDistribution ===
                    TicketDistributionType.ETickets ||
                    corporateBookingDetails.ticketDistribution ===
                      TicketDistributionType.Mixed) && (
                    <ButtonActionDetailGroup.Item className="btn btn-primary">
                      <Link
                        className="text-white d-block"
                        to={`/corporate_bookings/${id}/etickets`}
                      >
                        Distribute Ticket
                      </Link>
                    </ButtonActionDetailGroup.Item>
                  )}
                </>
              )}
              {instance.status === CorporateBookingStatus.Created && (
                <ButtonActionDetailGroup.Item
                  className="btn btn-primary search"
                  onClick={onPrintSample}
                >
                  Print Sample Ticket
                </ButtonActionDetailGroup.Item>
              )}
            </ButtonActionDetailGroup>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-4">
            <label className="form-control-label">Include 3D Glasses:</label>
          </div>
          <div className="col-md-1">
            <input
              disabled={_.isNil(performance) || mode === Mode.Readonly}
              className="mr-1"
              type="checkbox"
              checked={ticketDetails.has3dGlasses}
              name="ticketDetails.has3dGlasses"
              valuetype="boolean"
              onChange={onInputChange}
            />
          </div>
          <div className="col-md-7">
            <input
              disabled={_.isNil(performance) || mode === Mode.Readonly}
              className={getInputCssClass()}
              placeholder="Enter Quantity For 3D Glasses"
              type="number"
              name="ticketDetails.glassesQuantity"
              value={ticketDetails.glassesQuantity}
              valuetype="number"
              onChange={onInputChange}
              style={
                ticketDetails.has3dGlasses
                  ? { display: "flex" }
                  : { display: "none" }
              }
            />
          </div>
        </div>
        <div className="row form-group">
          <div className="col-md-4">
            <label className="form-control-label text-nowrap">
              Payment Options:
              <span className="color-red font-weight-bold">*</span>:
            </label>
          </div>
          <div className="col-md-8">
            {mode === Mode.Readonly && (
              <input
                className="form-control display-input"
                value={PaymentOption.getName(
                  corporateBookingDetails.paymentOption
                )}
                readOnly
              />
            )}
            {mode === Mode.Editable && (
              <select
                className="form-control"
                name="corporateBookingDetails.paymentOption"
                valuetype="number"
                value={corporateBookingDetails.paymentOption}
                onChange={onInputChange}
              >
                <option value="">Select One</option>
                {PaymentOption.All.map((option, index) => (
                  <option value={option.value} key={index}>
                    {option.name}
                  </option>
                ))}
              </select>
            )}
          </div>
        </div>
        {corporateBookingDetails.paymentOption !==
          PaymentOption.PrepaidTickets && (
          <div className="row form-group">
            <div className="col-md-4">
              <label className="form-control-label">
                Payment Amount:
                <span style={{ color: "red" }} className="red font-weight-bold">
                  *
                </span>
              </label>
            </div>
            <div className="col-md-8">
              {mode === Mode.Readonly && (
                <input
                  className="form-control display-input"
                  value={corporateBookingDetails.salesAmount}
                  readOnly
                />
              )}
              {mode === Mode.Editable && (
                <input
                  className="form-control"
                  type="number"
                  min="1"
                  valuetype="number"
                  name="corporateBookingDetails.salesAmount"
                  value={corporateBookingDetails.salesAmount}
                  onChange={onInputChange}
                />
              )}
            </div>
          </div>
        )}
        <div className="row form-group">
          <div className="col-md-4">
            <label className="form-control-label">Requested by:</label>
          </div>
          <div className="col-md-8">
            <input
              className={`form-control ${
                mode === Mode.Readonly ? "display-input" : ""
              }`}
              type="text"
              name="corporateBookingDetails.requestedBy"
              value={corporateBookingDetails.requestedBy}
              onChange={onInputChange}
              readOnly={mode === Mode.Readonly}
            />
          </div>
        </div>
        <FieldWrapper
          label="Campaign"
          additionalCss="form-group"
          input={
            <>
              {mode === Mode.Readonly && (
                <input
                  className="form-control display-input"
                  value={
                    campaignOptions.find((x) => x.id === instance.campaignId)
                      ?.name ?? ""
                  }
                  readOnly
                />
              )}
              {mode === Mode.Editable && (
                <CustomSelect
                  data={campaignOptions}
                  label="name"
                  value="id"
                  selectedValue={instance.campaignId}
                  onChange={onSelectCampaign}
                />
              )}
            </>
          }
        />
        <FieldWrapper
          label="Campaign Bundle"
          additionalCss="form-group"
          input={
            <>
              {mode === Mode.Readonly && (
                <input
                  className="form-control display-input"
                  value={getDisplayedCampaignBundle()}
                  readOnly
                />
              )}
              {mode === Mode.Editable && (
                <CustomSelect
                  data={campaignBundles}
                  label="displayName"
                  value="id"
                  selectedValue={instance.campaignBundleId}
                  onChange={onSelectCampaignBundle}
                />
              )}
            </>
          }
        />
      </Card>
    );
  };

  const renderClient = () => {
    return (
      <Card title="Client Details">
        <ButtonActionDetailGroup>
          <ButtonActionDetailGroup.Item
            className="btn btn-primary mr-2 search"
            onClick={() => setShowSearchClient(true)}
            disabled={mode === Mode.Readonly}
          >
            Choose Existing Client
          </ButtonActionDetailGroup.Item>
          <ButtonActionDetailGroup.Item
            className="btn btn-primary"
            onClick={() => setShowNewClient(true)}
            disabled={mode === Mode.Readonly}
          >
            Add New Client
          </ButtonActionDetailGroup.Item>
        </ButtonActionDetailGroup>
        {!_.isNil(client) && (
          <>
            <div className="row form-group">
              <div className="col-md-4">
                <label className="form-control-label">Company Code:</label>
              </div>
              <div className="col-md-8">
                <input
                  className="form-control display-input"
                  value={client.code}
                  type="text"
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-4">
                <label className="form-control-label" htmlFor="CompanyName">
                  Company Name:
                </label>
              </div>
              <div className="col-md-8">
                <input
                  className="form-control display-input"
                  type="text"
                  value={client.name}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-4">
                <label className="form-control-label" htmlFor="CustName">
                  Client Name:
                </label>
              </div>
              <div className="col-md-8">
                <input
                  className="form-control display-input"
                  type="text"
                  value={client.clientName}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-4">
                <label className="form-control-label" htmlFor="CompanyType">
                  Company Type:
                </label>
              </div>
              <div className="col-md-8">
                <input
                  className="form-control display-input"
                  value={CompanyType.getName(client.companyType)}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-4">
                <label className="form-control-label" htmlFor="ContactNo">
                  Contact Number:
                </label>
              </div>
              <div className="col-md-8">
                <input
                  className="form-control display-input"
                  type="tel"
                  value={client.contactNumber}
                  inputMode="tel"
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-4">
                <label className="form-control-label" htmlFor="Email">
                  Email Address:
                </label>
              </div>
              <div className="col-md-8">
                <input
                  className="form-control display-input"
                  type="email"
                  value={client.emailAddress}
                  inputMode="email"
                  readOnly={true}
                />
              </div>
            </div>
          </>
        )}
        {client && client.ticketTypeId && (
          <>
            <div className="row form-group">
              <div className="col-md-4">
                <label className="form-control-label">Ticket Type:</label>
              </div>
              <div className="col-md-8">
                <input
                  className="form-control display-input"
                  type="text"
                  value={client.ticketTypeId}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-4">
                <label className="form-control-label">Prepaid Quantity:</label>
              </div>
              <div className="col-md-8">
                <input
                  className="form-control display-input"
                  type="text"
                  value={client.prepaidTicketQuantity}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-4">
                <label className="form-control-label">
                  Used Ticket Quantity:
                </label>
              </div>
              <div className="col-md-8">
                <input
                  className="form-control display-input"
                  type="text"
                  value={client.usedTicketQuantity}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-4">
                <label className="form-control-label">
                  Remaining Ticket Quantity:
                </label>
              </div>
              <div className="col-md-8">
                <input
                  className="form-control display-input"
                  type="text"
                  value={client.remainingTicketQuantity}
                  readOnly={true}
                />
              </div>
            </div>
          </>
        )}
      </Card>
    );
  };
  const renderTicketAndCoupon = () => {
    const { corporateBookingDetails, ticketDetails } = instance;
    return (
      <Card title="Ticket &amp; Coupon">
        <div className="row">
          <div className="col-xl-6">
            <div className="row form-group">
              <div className="col-md-4">
                <label className="form-control-label">Disclaimer :</label>
              </div>
              <div className="col-md-8">
                <input
                  className={getInputCssClass()}
                  name="corporateBookingDetails.disclaimer"
                  value={corporateBookingDetails.disclaimer}
                  placeholder={
                    mode === Mode.Editable ? "Enter  Disclaimer" : ""
                  }
                  onChange={onInputChange}
                  readOnly={mode === Mode.Readonly}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-4">
                <label className="form-control-label" htmlFor="MovieTitle">
                  Movie Title:
                </label>
              </div>
              <div className="col-md-8">
                <input
                  className={getInputCssClass()}
                  name="ticketDetails.moviePrimaryTitle"
                  value={ticketDetails.moviePrimaryTitle}
                  placeholder={
                    mode === Mode.Editable ? "Enter Movie Title" : ""
                  }
                  onChange={onInputChange}
                  readOnly={mode === Mode.Readonly}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-4">
                <label className="form-control-label">Movie Rating:</label>
              </div>
              <div className="col-md-8">
                <input
                  className={getInputCssClass()}
                  name="ticketDetails.movieRating"
                  value={ticketDetails.movieRating}
                  placeholder={
                    mode === Mode.Editable ? "Enter Movie Rating" : ""
                  }
                  onChange={onInputChange}
                  readOnly={mode === Mode.Readonly}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-4">
                <label className="form-control-label" htmlFor="ticketdetails">
                  Ticket Details:
                </label>
              </div>
              <div className="col-md-8">
                <input
                  className={getInputCssClass()}
                  name="ticketDetails.ticketDetails"
                  value={ticketDetails.ticketDetails}
                  placeholder={
                    mode === Mode.Editable ? "Enter Ticket Details" : ""
                  }
                  onChange={onInputChange}
                  readOnly={mode === Mode.Readonly}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-4">
                <label className="form-control-label" htmlFor="TicketType">
                  Ticket Type:
                </label>
              </div>
              <div className="col-md-8">
                <input
                  className={getInputCssClass()}
                  value={ticketDetails.ticketType}
                  name="ticketDetails.ticketType"
                  placeholder={
                    mode === Mode.Editable ? "Enter Ticket Type" : ""
                  }
                  onChange={onInputChange}
                  readOnly={mode === Mode.Readonly}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-4">
                Has Ticket Details printed on ticket?
              </div>
              <div className="col-md-2">
                <div className="form-check">
                  <input
                    className="mr-1"
                    type="radio"
                    name="ticketDetails.hasPrintPrice"
                    value={true}
                    checked={ticketDetails.hasPrintPrice}
                    onChange={onInputChange}
                    disabled={mode === Mode.Readonly}
                  />
                  <label className="form-check-label">Yes</label>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-check">
                  <input
                    className="mr-1"
                    type="radio"
                    name="ticketDetails.hasPrintPrice"
                    value={false}
                    checked={!ticketDetails.hasPrintPrice}
                    onChange={onInputChange}
                    disabled={mode === Mode.Readonly}
                  />
                  <label className="form-check-label">No</label>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-4">Has print seat number on ticket?</div>
              <div className="col-md-2">
                <div className="form-check">
                  <input
                    className="mr-1"
                    type="radio"
                    name="ticketDetails.hasPrintSeatNumber"
                    value={true}
                    checked={ticketDetails.hasPrintSeatNumber}
                    onChange={onInputChange}
                    disabled={mode === Mode.Readonly}
                  />
                  <label className="form-check-label">Yes</label>
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-check">
                  <input
                    className="mr-1"
                    type="radio"
                    name="ticketDetails.hasPrintSeatNumber"
                    value={false}
                    checked={!ticketDetails.hasPrintSeatNumber}
                    onChange={onInputChange}
                    disabled={mode === Mode.Readonly}
                  />
                  <label className="form-check-label">No</label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 overflow-x mx-3 px-0">
            <div className="card preview-slipbg coupon-preview mb-2">
              <div className="card-body pb-0">
                <h4 className="card-title">
                  <span>
                    {_.isEmpty(ticketDetails.moviePrimaryTitle)
                      ? performance?.primaryTitle
                      : ticketDetails.moviePrimaryTitle}
                  </span>
                  <span>
                    (
                    <span id="classifycode">
                      {_.isEmpty(ticketDetails.movieRating)
                        ? Classify.getName(performance?.classify)
                        : ticketDetails.movieRating}
                    </span>
                    )
                  </span>
                </h4>
                <div className="row">
                  <p
                    style={{ fontSize: "14px" }}
                    id="Disclaimer"
                    className="pl-3"
                  >
                    {_.isEmpty(corporateBookingDetails.disclaimer)
                      ? "Violence And Brief Coarse Language"
                      : corporateBookingDetails.disclaimer}
                  </p>
                </div>
                <div className="row">
                  <div className="col-6 pr-0">
                    <small className="float-left preview-rotatetext mt-2">
                      DATE
                    </small>
                    <h2 className="mb-0" style={{ fontSize: "26px" }}>
                      {" "}
                      19-06-2019
                    </h2>
                  </div>
                  <div className="col-4 pr-0 pl-0">
                    <small className="float-left preview-rotatetext mt-2">
                      TIME
                    </small>
                    <h2 className="mb-0" style={{ fontSize: "25px" }}>
                      4:00PM
                    </h2>
                  </div>
                  <div className="col-2 pr-0 pl-0">
                    <small className="mb-0 float-left preview-rotatetext mt-2">
                      SEAT
                    </small>
                    <h2 className="mb-0" style={{ fontSize: "26px" }}>
                      07
                    </h2>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 pl-0">
                    <img className="w-100" src="../img/testqrcode.png" />
                  </div>
                  <div className="col-8">
                    <div className="row pt-3">
                      <div className="col-10 pl-0">
                        <h4>
                          <span id="ticketlocation">LIDO&nbsp;HALL</span>
                        </h4>
                      </div>
                      <div className="col-1">
                        <h1>3</h1>
                      </div>
                    </div>
                    <div className="row mt-3">cash</div>
                    <div className="row">
                      <small>
                        {_.isEmpty(ticketDetails.ticketDetails)
                          ? performance?.service.name
                          : ticketDetails.ticketDetails}
                      </small>
                    </div>
                    <div className="row">
                      <small>
                        {_.isEmpty(ticketDetails.ticketType)
                          ? "Adult"
                          : ticketDetails.ticketType}{" "}
                        1 Only
                      </small>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <small>SHAW THEATRES PTE LTD</small>
                  </div>
                  <div className="col-6">
                    <small className="float-right">
                      Co. Reg. No: 194700158G
                    </small>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <small>
                      <span>19/06/2019 12:03 PM</span>
                    </small>
                  </div>
                  <div className="col-6">
                    <small className="float-right">testlido.cso</small>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <small>S2E89B0000E29</small>
                  </div>
                  <div className="col-6">
                    <small className="float-right">E001-0100-85-003595</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {renderCouponComponent()}
      </Card>
    );
  };
  const onRemoveCoupon = () => {
    setShowCoupon(false);
    setInstance((data) => {
      return {
        ...data,
        couponDetails: new CorporateBookingCouponDetails(),
      };
    });
  };
  const onCouponTypeChange = (e) => {
    const newType = +e.target.value;
    const cloned = { ...instance };
    cloned.couponDetails.couponType = newType;
    if (newType === CouponType.Custom) {
      cloned.couponDetails.couponName = "";
      cloned.couponDetails.couponDetails = "";
    } else {
      cloned.couponDetails.couponName = "Safra Discount";
      cloned.couponDetails.couponDetails =
        "Present this coupon at MovieBites to enjoy $1 Off Popcorn (Sweet/Salted) Combo Set (Regular/Large/Family), Hotdog or Nachos Combo Set";
    }
    setInstance(cloned);
  };
  const renderCouponComponent = () => {
    const { couponDetails } = instance;
    return (
      <>
        <ButtonActionDetailGroup>
          {!showCoupon && (
            <ButtonActionDetailGroup.Item
              className="btn btn-primary"
              onClick={() => setShowCoupon(true)}
              disabled={mode === Mode.Readonly}
            >
              Add Coupon
            </ButtonActionDetailGroup.Item>
          )}
          {showCoupon && (
            <ButtonActionDetailGroup.Item
              className="btn btn-primary"
              onClick={onRemoveCoupon}
              disabled={mode === Mode.Readonly}
            >
              Remove Coupon
            </ButtonActionDetailGroup.Item>
          )}
        </ButtonActionDetailGroup>
        {showCoupon && couponDetails && (
          <div className="row form-group">
            <div className="col-xl-6">
              <div className="row mb-3">
                <div className="col-md-12">Coupon Type:</div>
              </div>
              <div className="row mb-3">
                <div className="col-md-2">
                  <div className="form-check">
                    <input
                      className="mr-1"
                      type="radio"
                      name="couponType"
                      value={CouponType.Custom}
                      checked={couponDetails.couponType === CouponType.Custom}
                      onChange={onCouponTypeChange}
                      disabled={mode === Mode.Readonly}
                    />
                    <label className="form-check-label">Custom</label>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-check">
                    <input
                      className="mr-1"
                      type="radio"
                      name="couponType"
                      value={CouponType.Premiere}
                      checked={couponDetails.couponType === CouponType.Premiere}
                      onChange={onCouponTypeChange}
                      disabled={mode === Mode.Readonly}
                    />
                    <label className="form-check-label">Premiere</label>
                  </div>
                </div>
              </div>

              <div className="row form-group">
                <div className="col-md-3">
                  <label className="form-control-label">Coupon Name:</label>
                </div>
                <div className="col-md-9">
                  <input
                    className={getInputCssClass()}
                    name="couponDetails.couponName"
                    value={couponDetails.couponName}
                    placeholder="Enter Coupon Name"
                    onChange={onInputChange}
                    readOnly={mode === Mode.Readonly}
                  />
                </div>
              </div>

              <div className="row form-group">
                <div className="col-md-3">
                  <label
                    className="form-control-label mt-2"
                    htmlFor="CoupDetails"
                  >
                    Coupon Details:
                  </label>
                </div>
                <div className="col-md-9">
                  <textarea
                    className={getInputCssClass()}
                    name="couponDetails.couponDetails"
                    style={{ fontSize: "80%", resize: "none" }}
                    placeholder="Enter Coupon Details"
                    rows="7"
                    value={couponDetails.couponDetails}
                    onChange={onInputChange}
                    readOnly={mode === Mode.Readonly}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6 overflow-x mx-3 px-0">
              <div className="card coupon-preview preview-slipbg mb-2">
                <div className="card-body pb-0">
                  <h4 className="card-title">
                    COUPON SLIP
                    <br />
                    <small id="couponName">
                      {_.isEmpty(couponDetails.couponName)
                        ? "Product name"
                        : couponDetails.couponName}
                    </small>
                  </h4>

                  <div className="row">
                    <div className="col-4" style={{ paddingLeft: "0" }}>
                      <img className="w-100" src="../img/testqrcode.png" />
                    </div>
                    <div className="col-8 coup-preview-detail">
                      <small
                        style={{
                          fontSize: "70%",
                          display: "block",
                          lineHeight: "17px",
                        }}
                        className="coup-details"
                      >
                        {_.isEmpty(couponDetails.couponDetails)
                          ? "Product details"
                          : couponDetails.couponDetails}
                      </small>
                    </div>
                    <small
                      className="col-4 pl-0 text-center"
                      style={{ marginTop: "-18px" }}
                    >
                      C213
                    </small>
                  </div>

                  <div className="row mt-3" style={{ fontSize: "80%" }}>
                    <div className="col-sm-6">
                      Pay Type :<label className="payTypeLabel">cash</label>
                    </div>
                    <div className="col-sm-6 text-right">
                      Valid Till :
                      <label className="payTypeLabel">19/06/2019</label>
                    </div>
                  </div>
                  <div className="row pb-2 ml-0" style={{ fontSize: "90%" }}>
                    <small id="coup-terms1">
                      *THIS COUPON IS VALID ON THE SAME DAY OF MOVIE
                    </small>
                    <small id="coup-terms2">
                      *THIS COUPON IS NOT EXCHANGEABLE FOR CASH OR OTHER ITEMS.
                    </small>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <small>SHAW THEATRES PTE LTD</small>
                    </div>

                    <div className="col-sm-6">
                      <small className="float-right">
                        Co. Reg. No: 194700158G
                      </small>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <small>S2E89B0000E29</small>
                    </div>
                    <div className="col-sm-5">
                      <small>19/06/2019 12:03 PM</small>
                    </div>
                    <div className="col-sm-3">
                      <small style={{ float: "right" }}>testlido.cso</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const renderBottomActions = () => {
    return (
      <ButtonActionDetailGroup>
        <ButtonActionDetailGroup.Item
          className="btn btn-success"
          data-toggle="modal"
          data-target="#submitModal"
          onClick={() => setShowSaveConfirmation(true)}
        >
          Save Changes
        </ButtonActionDetailGroup.Item>
        <ButtonActionDetailGroup.Item
          className="btn btn-danger"
          data-toggle="modal"
          data-target="#cancelModal"
          onClick={() => setShowCancelConfirmation(true)}
        >
          Cancel
        </ButtonActionDetailGroup.Item>
      </ButtonActionDetailGroup>
    );
  };

  const onHideHistoryDialog = () => {
    setShowHistoryDialog(false);
  };

  const onViewHistoryClick = () => {
    setShowHistoryDialog(true);
  };

  const canSelectSeat = () => {
    const { seatCodes, guestCount } = instance.corporateBookingDetails;
    if (!seatCodes) {
      return true;
    }
    return seatCodes.length < guestCount;
  };

  const isDreamersBalestier =
    performance &&
    performance.locationVenueBrand === LocationBrand.DreamersBalestier;
  return (
    <>
      <div className="main-content">
        <BreadCrumb navigationSettings={getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            {mode === Mode.Readonly && (
              <ButtonActionDetailGroup>
                {instance.status === CorporateBookingStatus.Created && (
                  <>
                    <ButtonActionDetailGroup.Item
                      className="btn btn-success pending"
                      onClick={() => setShowConfirmCorporateConfirmation(true)}
                    >
                      <i className="far fa-edit" /> Confirm
                    </ButtonActionDetailGroup.Item>
                    <ButtonActionDetailGroup.Item
                      className="btn btn-primary pending"
                      onClick={() => setMode(Mode.Editable)}
                    >
                      <i className="far fa-edit" /> Edit
                    </ButtonActionDetailGroup.Item>
                  </>
                )}
                {AuthenticationManager.isAuthorized(
                  ResourceID.CorporateBookingDetailCancelButton
                ) &&
                  (instance.status === CorporateBookingStatus.Created ||
                    instance.status === CorporateBookingStatus.Confirmed) && (
                    <ButtonActionDetailGroup.Item
                      className="btn btn-danger pending"
                      onClick={() => setShowCancelCorporateConfirmation(true)}
                    >
                      Cancel Booking
                    </ButtonActionDetailGroup.Item>
                  )}
                <ButtonActionDetailGroup.Item
                  className="btn btn-outline-secondary"
                  onClick={onViewHistoryClick}
                >
                  History
                </ButtonActionDetailGroup.Item>
              </ButtonActionDetailGroup>
            )}
            <div className="d-flex flex-row justify-content-end mb-3" />
            <div className="row">
              <div className="col-xl-7">{renderMovieSessionDetails()}</div>
              <div className="col-xl-5">{renderClient()}</div>
            </div>
            {!isDreamersBalestier && performance && (
              <SeatPlanComponent
                performanceId={performance.id}
                guestCount={instance.corporateBookingDetails.guestCount}
                preSelectedSeats={instance.corporateBookingDetails.seatCodes}
                onChangeSeatCodes={onChangeSeatCodes}
                readOnly={mode === Mode.Readonly}
                canSelectSeat={canSelectSeat}
                reload={reloadSeatSelection}
                remainingCapacity={totalRemainingCapacity}
              />
            )}
            {isDreamersBalestier && (
              <BalestierDreamerSeatPlan
                performanceId={performance.id}
                guestCount={instance.corporateBookingDetails.guestCount}
                preSelectedSeats={instance.corporateBookingDetails.seatCodes}
                onChangeSeatCodes={onChangeSeatCodes}
                readOnly={mode === Mode.Readonly}
                corporateBookingId={id}
              />
            )}
            {renderTicketAndCoupon()}
            {mode === Mode.Editable && renderBottomActions()}
          </div>
        </div>
      </div>
      {showHistoryDialog && (
        <HistoryDialog
          objectId={id}
          onClose={onHideHistoryDialog}
          url="/api/v1/history_logs"
          objectType={ObjectType.CorporateBooking}
        />
      )}
      <ClientSelectionModal
        visible={showSearchClient}
        onSelect={onSelectClient}
        onCancel={() => setShowSearchClient(false)}
      />
      <NewClientModal
        visible={showNewClient}
        onSelect={onSelectClient}
        onCancel={() => setShowNewClient(false)}
      />
      <ConfirmDialog
        visible={showSaveConfirmation}
        title="Save Changes"
        message="Are you sure you want to save the changes?"
        onProceed={onSaveChanges}
        onCancel={() => setShowSaveConfirmation(false)}
      />
      <ConfirmDialog
        visible={showConfirmCorporateConfirmation}
        title="Confirm Corporate Bookings"
        onProceed={onConfirmBooking}
        onCancel={() => setShowConfirmCorporateConfirmation(false)}
        message="Are you sure you want to confirm this Corporate Bookings?"
      />
      <ConfirmDialog
        visible={showCancelConfirmation}
        title="Cancel Changes"
        message="Are you sure you want to cancel?"
        onProceed={onCancelEdit}
        onCancel={() => setShowCancelConfirmation(false)}
      />
      <ConfirmDialog
        visible={showCancelCorporateConfirmation}
        title="Cancel Corporate Bookings"
        onProceed={onCancelBooking}
        onCancel={() => setShowCancelCorporateConfirmation(false)}
        message="Are you sure you want to cancel this Corporate Bookings?"
      />
    </>
  );
};
