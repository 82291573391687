import moment from "moment";
import { Modal } from "bootstrap";

export const isNull = (value) => value == null; // undefined or null

export const isNotNull = (value) => !isNull(value);

export const isObject = (value) =>
  isNotNull(value) && typeof value === "object";

export const isArray = (value) => isNotNull(value) && Array.isArray(value);

export const isJSON = (value) => isObject(value) || isArray(value);

export const isString = (value) =>
  typeof value === "string" || value instanceof String;

export const isNumeric = (n) =>
  !Number.isNaN(parseFloat(n)) && Number.isFinite(parseFloat(n));

export const isGreaterThanZero = (v) => isNumeric(v) && +v > 0;

export const isFalsy = (v) =>
  isNull(v) ||
  v === false ||
  v === "" ||
  Number.isNaN(v) ||
  [0, -0].includes(v);

export const isTruthy = (v) => !isFalsy(v);

export const isEmpty = (value) => {
  if (isNull(value)) {
    return true;
  }
  if (value instanceof Array) {
    return value.length === 0;
  }
  if (value instanceof Object) {
    return Object.keys(value).length === 0;
  }
  if (value instanceof String) {
    return value.trim() === "";
  }
  return value.toString().trim() === "";
};

export const isValidHttpUrl = (string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
};

export class Strings {
  static format(text) {
    if (arguments.length <= 1) {
      return text;
    }
    let tokenCount = arguments.length - 2;
    for (let token = 0; token <= tokenCount; token++) {
      text = text.replace(
        new RegExp("\\{" + token + "\\}", "gi"),
        arguments[token + 1]
      );
    }
    return text;
  }

  static isNullOrEmpty(text) {
    return (
      text === undefined || text === null || text === "" || text.trim() === ""
    );
  }

  static equalsIgnoreCase(string1, string2) {
    if (!Strings.isNullOrEmpty(string1) && !Strings.isNullOrEmpty(string2)) {
      return string1.toLowerCase() === string2.toLowerCase();
    }
    return string1 === string2;
  }

  static generateRandomString() {
    let result = "";
    let characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let charactersLength = characters.length;
    for (let i = 0; i < 10; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}

export function log() {
  if (typeof console != "undefined") {
    Function.prototype.apply.call(console.log, console, arguments);
  }
}

export function getSelectors(selectors, selectorType) {
  let data = [];
  selectors
    .filter((selector) => selector.type === selectorType)
    .forEach((item) => {
      let isDuplicated = data.indexOf(item.code) >= 0;
      if (!isDuplicated) {
        data.push(item);
      }
    });
  return data;
}

export const formatDateTime = (datetime) => {
  return moment(datetime).format("DD/MM/yyyy HH:mm");
};

export const getDateTimeLocal = (datetime = Date.now(), daysToAdd) => {
  return moment(datetime).add('d', daysToAdd + 1).format("YYYY-MM-DDTHH:mm");
}

export class DateTimeHelper {
  static DateTimeWithSlashFormat = "{0}/{1}/{2}";
  static DateTimeWithMinusFormat = "{0}-{1}-{2}";
  static DateTimeWithSpaceFormat = "{0} {1} {2}";
  static TodayLongDateFormat = "Today, {0}, {1} {2} {3}";
  static NormalLongDateFormat = "{0}, {1} {2} {3}";
  static ShortDateFormat = "{0}, {1} {2}";

  static formatDate = function (date, format) {
    let selectorMonth = date.getMonth() + 1; //months from 1-12
    let selectorDay = date.getDate();
    let selectorYear = date.getFullYear();
    return Strings.format(format, selectorYear, selectorMonth, selectorDay);
  };

  static getLongDateTime = function (date) {
    let selectorDay = date.getDate();
    let selectorYear = date.getFullYear();
    let currentNewDate = this.getCurrentDate(this.DateTimeWithSlashFormat);
    let selectorNewDate = this.formatDate(date, this.DateTimeWithSlashFormat);

    let dayOfWeek = date.toLocaleDateString("en-US", { weekday: "short" });
    let monthData = date.toLocaleDateString("en-US", { month: "short" });
    if (currentNewDate === selectorNewDate) {
      return Strings.format(
        this.TodayLongDateFormat,
        dayOfWeek,
        selectorDay,
        monthData,
        selectorYear
      );
    }
    return Strings.format(
      this.NormalLongDateFormat,
      dayOfWeek,
      selectorDay,
      monthData,
      selectorYear
    );
  };

  static getCurrentDate = function (format) {
    let dateObj = new Date();
    let currentMonth = dateObj.getUTCMonth() + 1; //months from 1-12
    let currentDay = dateObj.getUTCDate();
    let currentYear = dateObj.getUTCFullYear();
    return Strings.format(format, currentYear, currentMonth, currentDay);
  };
}

export class Base64String {
  static getBase64String = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };

  static getBase64StringFromDataURL = (dataURL) => {
    return dataURL.replace("data:", "").replace(/^.+,/, "");
  };
}

export const getMovieClassifyCssClassById = (id) => {
  const cssClassMap = {
    0: "",
    1: "sprite-legends-rating-g inline vert-align-mid mar-right-5",
    2: "sprite-legends-rating-pg inline vert-align-mid mar-right-5",
    3: "sprite-legends-rating-pg13 inline vert-align-mid mar-right-5",
    4: "sprite-legends-rating-nc16 inline vert-align-mid mar-right-5",
    5: "sprite-legends-rating-m18 inline vert-align-mid mar-right-5",
    6: "sprite-legends-rating-r21 inline vert-align-mid mar-right-5",
    7: "sprite-showtimes-rating-tba inline vert-align-mid mar-right-5",
    8: "sprite-showtimes-rating-tba-filled inline vert-align-mid mar-right-5",
  };
  if (!id) return "";
  let cssClass = cssClassMap[id];
  return cssClass ? cssClass : "";
};

export const showLoading = (value) => {
  localStorage.setItem("isLoading", value);
  window.dispatchEvent(new Event("storage"));
};

export const getModalInstant = (id) =>
  new Modal(document.getElementById(id), {
    backdrop: "static",
    keyboard: false,
  });
