import { StorageKeys } from "../common/Constants";
export class ResourceID {
  static AccessAllDepartments = "spc_no_dept_lim";

  static TransactionListingPage = "tranx_lst";
  static TransactionDetailPage = "tranx_dtl";
  static ResendConfirmationEmail = "tranx_email_rsd";
  static TransactionRefundListingPage = "tranx_refd_lst";
  static CreateRefundRequestButton = "tranx_refd_crt";
  static HallTransactionEnquiryPage = "tranx_hall_rpt";
  static RefundListingPage = "tranx_refd_lst";
  static RefundDetailPage = "tranx_refd_dtl";
  static RefundApproveRejectButton = "tranx_refd_apr";
  static RefundButton = "tranx_refd_init";
  static SubmitToBankButton = "tranx_refd_sbm";

  static BookingRequestListingPage = "bk_req_lst";
  static BookingRequestDetailPage = "bk_req_dtl";
  static BookingRequestEditButtonPage = "bk_req_edt";
  static BookingRequestCompleteRejectButton = "bk_req_cmp";
  static CorporateBookingListingPage = "corp_bk_lst";
  static CorporateBookingCreateButtonPage = "corp_bk_crt";
  static CorporateBookingDetailPage = "corp_bk_dtl";
  static CorporateBookingDetailSeatSelectionButtonPage = "corp_bk_dtl_seat";
  static CorporateBookingDetailCancelButton = "corp_bk_dtl_cnl";
  static CorporateBookingDetailDistributeTicketButtonPage = "corp_bk_dtl_dist";
  static CorporateBookingDetailPrintButton = "corp_bk_dtl_prnt";
  static CorporateBookingEditPage = "corp_bk_edt";
  static CorporateClientListingPage = "corp_cli_lst";
  static CorporateClientCreateButtonPage = "corp_cli_crt";
  static CorporateClientDetailPage = "corp_cli_dtl";
  static CorporateClientEditPage = "corp_cli_edt";

  static UserListingPage = "usr_lst";
  static CreateNewUserButtonPage = "usr_crt";
  static UserDetailPage = "usr_dtl";
  static UserProfilePage = "usr_pfl";
  static EditUserButtonPage = "usr_edt";
  static UserDeactivateActivateButton = "usr_stt";
  static ResetPasswordButton = "usr_pwd_rst";

  static VoucherListingPage = "vch_lst";
  static VoucherRegistrationPage = "vch_reg";
  static VoucherActivationPage = "vch_act";
  static VoucherExtensionRequestCreationPage = "vch_ext_crt";
  static VoucherExtensionRequestListingPage = "vch_ext_lst";
  static VoucherExtensionRequestDetailPage = "vch_ext_dtl";
  static VoucherExtensionRequestApproveRejectButton = "vch_ext_apr";
  static VoucherProgramListingPage = "vch_prg_lst";
  static VoucherProgramDetailPage = "vch_prg_dtl";
  static VoucherUsageInternalListingPage = "vch_usg_in_lst";
  static VoucherUsageInternalDetailPage = "vch_usg_in_dtl";
  static VoucherUsageExternalListingPage = "vch_usg_ex_lst";
  static VoucherUsageExternalDetailPage = "vch_usg_ex_dtl";

  static VoucherRequestListingPage = "vch_req_lst";
  static VoucherRequestViewPage = "vch_req_dst";
  static EditVoucherRequestButton = "vch_req_edt";
  static VoucherRequestApprovalRejectButton = "vch_req_apr";

  static LocationListingPage = "loc_lst";
  static LocationScheduledListingPage = "loc_sch_lst";
  static LocationScheduledDetailPage = "loc_sch_dtl";
  static LocationScheduledEditButtonPage = "loc_sch_edt";
  static LocationDetailPage = "loc_dtl";
  static LocationEditButtonPage = "loc_edt";
  static LocationScheduleButton = "loc_sch";

  static CareerListingPage = "crr_lst";
  static CareerCreateButtonPage = "crr_crt";
  static CareerDetailPage = "crr_dtl";
  static CareerEditButtonPage = "crr_edt";

  static PromotionListingPage = "prom_lst";
  static PromotionScheduledListingPage = "prom_sch_lst";
  static PromotionScheduledDetailPage = "prom_sch_dtl";
  static PromotionScheduledEditButtonPage = "prom_sch_edt";
  static PromotionDetailPage = "prom_dtl";
  static PromotionEditButtonPage = "prom_edt";
  static PromotionScheduleButton = "prom_sch";
  static PromotionCreateButtonPage = "prom_crt";

  static ContestListingPage = "cts_lst";
  static ContestScheduledListingPage = "cts_sch_lst";
  static ContestScheduledDetailPage = "cts_sch_dtl";
  static ContestScheduledEditButtonPage = "cts_sch_edt";
  static ContestDetailPage = "cts_dtl";
  static ContestEditButtonPage = "cts_edt";
  static ContestScheduleButton = "cts_sch";
  static ContestCreateButtonPage = "cts_crt";

  static MovieVoucherListingPage = "mvch_lst";
  static MovieVoucherScheduledListingPage = "mvch_sch_lst";
  static MovieVoucherScheduledDetailPage = "mvch_sch_dtl";
  static MovieVoucherScheduledEditButtonPage = "mvch_sch_edt";
  static MovieVoucherDetailPage = "mvch_dtl";
  static MovieVoucherEditButtonPage = "mvch_edt";
  static MovieVoucherScheduleButton = "mvch_sch";
  static MovieVoucherCreateButtonPage = "mvch_crt";

  static FilmFestivalListingPage = "ffest_lst";
  static FilmFestivalScheduledListingPage = "ffest_sch_lst";
  static FilmFestivalScheduledDetailPage = "ffest_sch_dtl";
  static FilmFestivalScheduledEditButtonPage = "ffest_sch_edt";
  static FilmFestivalDetailPage = "ffest_dtl";
  static FilmFestivalEditButtonPage = "ffest_edt";
  static FilmFestivalScheduleButton = "ffest_sch";
  static FilmFestivalCreateButtonPage = "ffest_crt";

  static KinoLoungeListingPage = "knl_lst";
  static KinoLoungeCreateButtonPage = "knl_crt";
  static KinoLoungeDetailPage = "knl_dtl";
  static KinoLoungeEditButtonPage = "knl_edt";

  static PassListingPage = "pas_lst";
  static PassCreateButtonPage = "pas_crt";
  static PassDetailPage = "pas_dtl";
  static PassExtendButton = "pas_ext_lt";
  static PassActiveDeactiveButton = "pas_act";
  static PassHistoryButton = "pas_hts";
  static PassEditButtonPage = "pas_edt";
  static StaffPassListingPage = "spas_lst";
  static StaffPassCreateButtonPage = "spas_crt";
  static StaffPassDetailPage = "spas_dtl";
  static StaffPassExtendButton = "spas_ext_lt";
  static StaffPassActiveDeactiveButton = "spas_act";
  static StaffPassHistoryButton = "spas_hts";
  static StaffPassEditButtonPage = "spas_edt";
  static PassExtensionPage = "pas_ext";
  static PassProgramListingPage = "pas_prg_ext";
  static PassProgramDetailPage = "pas_prg_dtl";
  static PassUsageListingPage = "pas_usg_lst";
  static PassUsageDetailPage = "pas_usg_dtl";

  static ProductListingPage = "prod_lst";
  static ProductCreateButtonPage = "prod_crt";
  static ProductDetailPage = "prod_dtl";
  static ProductEditButtonPage = "prod_edt";
  static ProductMenuListingPage = "prod_mnu_lst";
  static ProductMenuAddingButtonPage = "prod_mnu_add";

  static ServiceListingPage = "svc_lst";
  static ServiceCreateButtonPage = "svc_crt";
  static ServiceViewPage = "svc_dtl";
  static ServiceViewEditButton = "svc_edt";

  static ServiceTicketPriceRequestListingPage = "svc_tprice_req_lst";
  static ServiceTicketPriceRequestDetailPage = "svc_tprice_req_dtl";
  static ServiceTicketPriceRequestApproveRejectButton = "svc_tprice_req_apr";
  static ServiceTicketPriceRequestCancelButton = "svc_tprice_req_ccl";
  static ServiceSurchargeListingPage = "svc_sur_lst";
  static ServiceSurchargeDetailPage = "svc_sur_dtl";
  static ServiceSurchargeEditButtonPage = "svc_sur_edt";
  static ServicePriceUpdateListingPage = "svc_pri_ud_lst";
  static ServicePriceUpdateCreateButtonPage = "svc_pri_ud_crt";
  static ServicePriceUpdateDetailPage = "svc_pri_ud_dtl";
  static ServicePriceUpdateApproveRejectButton = "svc_pri_ud_apr";
  static ServicePriceUpdateCancelButton = "svc_pri_ud_ccl";
  static PriceUpdateCreateButton = "prc_upd_crt";

  static MovieListingPage = "mov_lst";
  static MovieCreateButtonPage = "mov_crt";
  static MovieDetailPage = "mov_dtl";
  static MovieEditButtonPage = "mov_edt";
  static MovieMediaListingPage = "mov_med_lst";
  static MovieMediaDetailPage = "mov_med_dtl";
  static MovieMediaEditButtonPage = "mov_med_edt";
  static PerformanceListingPage = "perf_lst";
  static PerformanceDetailPage = "perf_dtl";
  static PerformanceActiveDeactiveButton = "perf_act";
  static PerformanceUpdateRestrictionButton = "perf_rest";
  static PerformanceSelectorListingPage = "perf_sl_lst";
  static PerformanceSelectorDetailPage = "perf_sl_dtl";

  static CampaignsListingPage = "cam_lst";
  static CampaignsCreation = "cam_crt";
  static CampaignsViewPage = "cam_dtl";
  static CampaignsViewPageEditFeatures = "cam_edt";
  static CampaignsViewPageBlock = "cam_blk";

  static EVoucherOrderListingPage = "evch_odr_lst";
  static EVoucherViewBatchDetailsPage = "evch_bat_dtl";
  static EVoucherBatchListingPage = "evch_bat_lst";
  static EVoucherListingPage = "evch_lst";
  static EVoucherViewDetailsPage = "evch_dst";
  static EVoucherExtensionRequestListingPage = "evch_ext_lst";
  static EVoucherExtensionRequestViewDetailsPage = "evch_ext_dtl";
  static EVoucherBatchExtensionRequestListingPage = "evch_bat_ext_req_lst";
  static EVoucherBatchExtensionRequestViewDetailsPage = "evch_bat_ext_req_dtl";
  static EVoucherProgramListingPage = "evch_pro_lst";
  static EVoucherProgramViewDetailsPage = "evch_pro_dtl";
  static EVoucherOrderViewDetailsPage = "evch_odr_dtl";
  static EVoucherOrderApprover = "evch_ord_approval";
  static EVoucherViewDetailsExtendValidityButton = "evch_dst_ext";
  static EVoucherExtensionRequestViewDetailsCancelButton = "evch_ext_dtl_ccl";
  static EVoucherBatchExtensionRequestViewDetailsApproveRejectButton =
    "evch_bat_ext_req_apr";
  static EVoucherBatchExtensionRequestViewDetailsCancelButton =
    "evch_bat_ext_req_ccl";
  static EVoucherOrderCreateButtonPage = "evch_odr_crt";
  static EVoucherOrderBatchCreateButton = "evch_odr_bat_crt";
  static EvoucherOrderCancelOrderButton = "evch_odr_ccl";
  static EVoucherViewBatchDetailsDownloadButton = "evch_bat_dwl";
  static EVoucherViewBatchDetailsPageCancelBatchButton = "evch_bat_ccl";
  static EVoucherViewDetailsBlockUnblockButton = "evch_dst_blk";
  static EVoucherViewBatchDetailsPageApproveRejectButton = "evch_bat_apr";
  static EVoucherExtensionRequestViewDetailsApproveRejectButton =
    "evch_ext_dtl_apr";
  static EvoucherViewBatchDetailsExtendValidityButton = "evch_bat_ext";

  static CustomContentListingPage = "cus_lst";
  static CustomContentViewDetailPage = "cus_dtl";
  static CustomContentEditButton = "cus_edt";
  static CustomContentActivateDeactivateButton = "cus_act";

  static MovieReleaseUpdateRestrictionButtonPage = "mov_rel_upd";
  static MovieReleaseCreateButtonPage = "mov_rel_crt";
  static MovieReleaseViewDetailsPage = "mov_rel_dtl";
  static MovieReleaseEditButtonPage = "mov_rel_edt";

  // Report
  static InternalReportsListingPage = "int_rpt";
  static TheatreReportsListingPage = "thr_rpt";
  static RequestReportsListingPage = "req_rpt";
  static DailyDocketExportPage = "dck_rpt";
  // static EdiReportPage = "edi_rpt";

  // Marathon
  static MarathonListingPage = "mara_lst";
  static MarathonDetailPage = "mara_dtl";

  //SpecialPrice
  static SpecialPricingListingPage = "sprc_lst";
  static SpecialPricingDetailsPage = "sprc_dtl";
  static SpecialPricingCreateButtonAndPage = "sprc_crt";
  static SpecialPricingEditButtonAndPage = "sprc_edt";
  static SpecialPricingDeleteButton = "sprc_del";

  // Cache Clearing
  static CacheClearPage = "cache_clear";

  static MovieReleasePromotionValidityListingPage = "mov_rel_prmvld_lst";
  static MovieReleasePromotionValidityCreationButtonPage = "mov_rel_prmvld_crt";
  static MovieReleasePromotionValidityDetailsPage = "mov_rel_prmvld_dtl";
  static MovieReleasePromotionValidityEditButtonPage = "mov_rel_prmvld_edt";
}

const AccessToken = "access_token";
const UserRoles = "user_roles";
const UserDepartment = "user_department";
const RefreshToken = "refresh_token";
const Username = "username";
const UserPermissions = "user_permissions";
const UserProfile = "user_profile";

export class AuthenticationManager {
  static clearAuthInfo() {
    const keys = [
      RefreshToken,
      Username,
      UserDepartment,
      UserPermissions,
      UserProfile,
      AccessToken,
      UserRoles,
    ];
    AuthenticationManager.disabled = false;
    AuthenticationManager.permissions = null;
    AuthenticationManager.userProfile = null;
    keys.forEach((key) => localStorage.removeItem(key));
  }

  static accessToken() {
    return localStorage.getItem(AccessToken);
  }

  static departmentId() {
    return AuthenticationManager.disabled
      ? ""
      : localStorage.getItem(UserDepartment);
  }

  static isAuthenticated() {
    if (!this.accessToken()) {
      localStorage.setItem(StorageKeys.ReturnPath, window.location.pathname);
    }
    return AuthenticationManager.disabled || !!this.accessToken();
  }

  static isAuthorized(resourceId) {
    if (AuthenticationManager.disabled || !resourceId) {
      return true;
    }
    if (!AuthenticationManager.permissions) {
      AuthenticationManager.permissions = JSON.parse(
        localStorage.getItem(UserPermissions)
      );
    }
    const permissions = AuthenticationManager.permissions;
    if (!Array.isArray(permissions)) {
      return false;
    }
    return permissions.includes(resourceId);
  }

  static profile() {
    if (!AuthenticationManager.userProfile) {
      const systemProfile = {
        firstName: "System",
        middleName: "Super",
        lastName: "Administrator",
        email: "root@shaw.sg",
      };
      let profile = AuthenticationManager.disabled
        ? systemProfile
        : JSON.parse(localStorage.getItem(UserProfile));
      if (!profile) {
        profile = {
          firstName: "Shaw",
          middleName: "",
          lastName: "User",
          email: "user@shaw.sg",
        };
      }
      AuthenticationManager.userProfile = profile;
    }
    return AuthenticationManager.userProfile;
  }

  static refreshToken() {
    return localStorage.getItem(RefreshToken);
  }

  static saveAuthInfo(authInfo) {
    AuthenticationManager.permissions = null;
    localStorage.setItem(AccessToken, authInfo.token.accessToken);
    localStorage.setItem(RefreshToken, authInfo.token.refreshToken);
    if (authInfo.user.username) {
      localStorage.setItem(Username, authInfo.user.username);
    }
    localStorage.setItem(UserDepartment, authInfo.user.departmentCode);
    localStorage.setItem(
      UserPermissions,
      JSON.stringify(authInfo.user.permissions)
    );
    localStorage.setItem(UserRoles, JSON.stringify(authInfo.user.roles));
    localStorage.setItem(
      UserProfile,
      JSON.stringify({
        firstName: authInfo.user.firstName || "",
        middleName: authInfo.user.middleName || "",
        lastName: authInfo.user.lastName || "",
        email: authInfo.user.email || "",
        username: authInfo.user.username || ""
      })
    );
  }

  static getRoles() {
    JSON.parse(localStorage.getItem(UserRoles));
  }

  static username() {
    return AuthenticationManager.disabled
      ? "system"
      : localStorage.getItem(Username);
  }
}
