import React from "react";
import {
  AuthenticationManager,
  ResourceID,
} from "../../../common/AuthenticationManager";
import { log, showLoading } from "../../../common/Common";
import { EvoucherOrderStatus, StatusColor } from "../../../common/Constants";
import { StringHelper } from "../../../common/Helpers";
import RestClient from "../../../common/RestClient";
import { BaseListing } from "../../common/BaseListing";
import { Link } from "react-router-dom";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";

export class EvoucherOrderListing extends BaseListing {
  constructor() {
    super();
    this.state = {
      evoucherProgram: [],
      evoucherOrderStatus: [],
      orderStatus: "",
      evoucherProgramId: 0,
      reference: "",
    };
  }

  async componentDidMount() {
    try {
      showLoading(true);
      await this.loadData(0);
      showLoading(false);
    } catch (error) {
      showLoading(false);
    }

    this.getEvoucherStatuses();
    this.getEvoucherPrograms();
  }

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "evouchers",
        name: "eVouchers",
      }),
      activeModule: new NavigationItem({
        identifier: "orders",
        name: "eVoucher Orders",
      }),
    });
  };

  getEvoucherStatuses = () => {
    RestClient.sendGetRequest(
      "/api/v1/evoucher_orders/statuses",
      (response) => {
        this.setState({ evoucherOrderStatus: response });
      },
      (err) => {
        log(err);
      }
    );
  };

  getEvoucherPrograms = () => {
    RestClient.sendGetRequest(
      "/api/v1/evoucher_orders/evoucher_programs",
      (response) => {
        this.setState({ evoucherProgram: response });
      },
      (err) => {
        log(err);
      }
    );
  };

  getApiPath = () => "/api/v1/evoucher_orders";

  getDefaultSort = () => "-UpdatedOn";

  injectSearchTerm(queryParameters, searchBy) {
    const reference = StringHelper.isNullOrEmpty(this.state.reference)
      ? ""
      : this.state.reference;
    const orderStatus = this.state.orderStatus;
    const evoucherProgramId = this.state.evoucherProgramId;
    queryParameters.includes = "EVoucherBatches";
    return super.injectSearchTerm(queryParameters, {
      reference,
      orderStatus,
      evoucherProgramId,
    });
  }

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  getStatusColor = (status) => {
    if (status === EvoucherOrderStatus.Approved) return StatusColor.Green;
    if (status === EvoucherOrderStatus.Pending) return StatusColor.Orange;
    if (status === EvoucherOrderStatus.Rejected) return StatusColor.Red;
    if (status === EvoucherOrderStatus.Cancelled) return StatusColor.Gray;
  };

  onEvoucherProgramChange = (e) => {
    this.setState({ evoucherProgramId: e.target.value });
  };

  onEvoucherStatusChange = (e) => {
    this.setState({ orderStatus: e.target.value });
  };

  generateTableFilter() {
    return (
      <div className="row mb-3">
        <div className="col-12 col-md-6 col-xl-4 row mb-2 mx-0 align-items-center">
          <label
            className="form-control-label col-6 px-0"
            htmlFor="EvoucherProgramId"
          >
            eVoucher Program:{" "}
          </label>
          <select
            className="form-control col-6"
            name="evoucherProgramId"
            id="EvoucherProgramId"
            required
            onChange={this.onEvoucherProgramChange}
            value={this.state.evoucherProgramId}
          >
            <option value={0}>All Programs</option>
            {this.state.evoucherProgram
              ? this.state.evoucherProgram.map((item, index) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  );
                })
              : null}
          </select>
        </div>

        <div className="col-12 col-md-6 col-xl-4 row mb-2 mx-0 align-items-center">
          <label
            className="form-control-label col-6 px-0"
            htmlFor="OrderStatus"
          >
            Status:{" "}
          </label>
          <select
            className="form-control col-6"
            name="orderStatus"
            id="OrderStatus"
            required
            onChange={this.onEvoucherStatusChange}
            value={this.state.orderStatus}
          >
            <option value="">All Status</option>
            {this.state.evoucherOrderStatus
              ? this.state.evoucherOrderStatus.map((item, index) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  );
                })
              : null}
          </select>
        </div>

        <div className="col-12 col-md-6 col-xl-4 row mx-0 mb-2 input-group ml-auto">
          <input
            type="text"
            id="input1-group2"
            name="reference"
            placeholder="Search by Order ID"
            className="form-control"
            onKeyDown={this.onKeyDownSearchInput}
            onChange={this.onInputChange}
          />
          <div className="input-group-btn">
            <button className="btn btn-primary" onClick={this.search}>
              <i className="fa fa-search" /> Search
            </button>
          </div>
        </div>
      </div>
    );
  }

  onRowClick = (id) => {
    this.props.history.push(`/evouchers/orders/${id}`);
  };

  generateTableContent() {
    return (
      <>
        <thead>
          <tr>
            <th width="15%">Order ID</th>
            <th>eVoucher Program</th>
            <th>Status</th>
            <th>Total Quantity</th>
            <th>Issued Quantity</th>
            <th>Company Name</th>
            <th>
              Updated On
              <i
                id="UpdatedOn"
                className="fas fa-sort-alpha-down"
                onClick={this.toggleSort}
              />
            </th>
            <th>Created By</th>
          </tr>
        </thead>
        <tbody>
          {this.state.data &&
            this.state.data.map((item) => (
              <tr
                key={item.id}
                className="pending"
                onClick={() => this.onRowClick(item.id)}
              >
                <td>{item.reference}</td>
                <td>{item.eVoucherProgram.shortName}</td>
                <td className={this.getStatusColor(item.status)}>
                  {EvoucherOrderStatus.getName(item.status)}
                </td>
                <td>{item.totalQuantity}</td>
                <td>{item.issuedQuantity}</td>
                <td>{item.companyName}</td>
                <td>
                  {StringHelper.asDateStringToDisplayDateTimeFormat(
                    item.updatedOn
                  )}
                </td>
                <td>{item.createdBy}</td>
              </tr>
            ))}
        </tbody>
      </>
    );
  }

  generateExtendedComponents() {
    return (
      AuthenticationManager.isAuthorized(
        ResourceID.EVoucherOrderCreateButtonPage
      ) && (
        <>
          <ButtonActionDetailGroup>
            <ButtonActionDetailGroup.Item>
              <Link
                className="btn btn-primary d-block"
                to="/evouchers/orders/create"
                style={{ fontSize: "1rem" }}
              >
                <i className="fas fa-plus-circle"></i> Create eVoucher Order
              </Link>
            </ButtonActionDetailGroup.Item>
          </ButtonActionDetailGroup>
        </>
      )
    );
  }
}
