import React from "react";
import { AuthenticationManager, ResourceID } from "../../../common/AuthenticationManager";
import { Link } from "react-router-dom";
import { StringHelper } from "../../../common/Helpers";
import { BaseListing } from "../../common/BaseListing";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";

export class ContestListing extends BaseListing {
  getApiPath = () => "/api/v1/contests";

  getDefaultSort = () => "+DisplayWeight";

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "website_managements",
        name: "Website Management",
      }),
      activeModule: new NavigationItem({
        identifier: "contests",
        name: "Contests",
      }),
    });
  };

  onRowClick = (id) => {
    this.props.history.push(`/contests/${id}`);
  };

  generateTableContent() {
    return (
      <React.Fragment>
        <thead>
          <tr>
            <th>Name</th>
            <th>Contest Start</th>
            <th>Contest End</th>
            <th>Display Start</th>
            <th>Display End</th>
            <th>
              Display Weight{" "}
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="DisplayWeight"
                onClick={this.toggleSort}
              />
            </th>
            <th>
              Updated On{" "}
              <i
                className="sort-button fas fa-sort-alpha-down"
                id="UpdatedOn"
                onClick={this.toggleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {this.state.data
            ? this.state.data.map((item, index) => {
              return (
                <tr key={index} onClick={() => this.onRowClick(item.id)}>
                  <td>{item.name}</td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.contestStartTime
                    )}
                  </td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.contestEndTime
                    )}
                  </td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.displayStartTime
                    )}
                  </td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.displayEndTime
                    )}
                  </td>
                  <td>{item.weightDisplay}</td>
                  <td>
                    {StringHelper.asDateStringToDisplayDateTimeFormat(
                      item.updatedOn
                    )}
                  </td>
                </tr>
              );
            })
            : null}
        </tbody>
      </React.Fragment>
    );
  }

  generateExtendedComponents() {
    return (
      <div className="d-flex flex-column flex-md-row justify-content-end mb-3">
        {
          AuthenticationManager.isAuthorized(
            ResourceID.ContestCreateButtonPage
          ) && (
            <Link
              className="btn btn-primary ml-2 mb-2"
              to="/contests/create"
              style={{ fontSize: "1rem" }}
            >
              <i className="fa fa-plus-circle"></i> Create Contest
            </Link>)
        }
        <Link
          className="btn btn-primary ml-2 mb-2"
          to="/contests/schedules"
          style={{ fontSize: "1rem" }}
        >
          Scheduled Publishing
        </Link>
      </div>
    );
  }
}
