import React, { useState } from "react";
import { BreadCrumb } from "../common/BreadCrumb";
import { Campaign } from "../../models/Campaign";
import {
  CampaignCategory,
  CampaignRestriction,
  DayOfWeek,
  RedemptionQuantityType,
} from "../../constants/CampaignConstants";
import _ from "lodash";
import RestClient from "../../common/RestClient";
import { TextInput } from "./TextInput";
import { TextAreaInput } from "./TextAreaInput";
import { FieldWrapper } from "./FieldWrapper";
import { DateTimeInput } from "./DateTimeInput";
import { NumberInput } from "./NumberInput";
import { SingleSelector } from "./SingleSelector";
import { ConfirmDialog } from "../common/ConfirmDialog";
import { PreviewImageDialog } from "../common/PreviewImageDialog";
import { toast } from "react-toastify";
import {
  NavigationItem,
  NavigationSettings,
} from "../common/NavigationSettings";
import { CheckboxInput } from "./CheckboxInput";
import { useHistory } from "react-router-dom";
import ButtonActionDetailGroup from "../common/ButtonActionDetailGroup";
import { Card } from "../common/Card";

export const CampaignCreation = () => {
  const [instance, setInstance] = useState(new Campaign({}));
  const [showPreview, setShowPreview] = useState(false);
  const [showCreateConfirmation, setShowCreateConfirmation] = useState(false);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const history = useHistory();

  const handleError = (error) => {
    toast.error(error.message);
  };

  const onInputChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    const valueType = e.target.getAttribute("valuetype");
    let selectValue = e.target.value;
    if (_.isEqual("number", valueType) && !isNaN(+selectValue)) {
      selectValue = +selectValue;
    }

    if (_.isEqual("boolean", valueType)) {
      selectValue = e.target.checked;
    }

    setInstance((campaign) => {
      return {
        ...campaign,
        [fieldName]: selectValue,
      };
    });
  };

  const onCloseAllModals = () => {
    setShowCancelConfirmation(false);
    setShowCreateConfirmation(false);
    setShowPreview(false);
  };

  const onCreate = () => {
    const body = {
      ...instance,
    };
    RestClient.sendPostRequest(
      "/api/v1/campaigns",
      body,
      (response) => {
        history.push(`/campaigns/${response.id}`);
        toast.success("The campaign has been created successful.");
      },
      handleError
    );
    onCloseAllModals();
  };

  const renderBottomButtons = () => {
    return (
      <ButtonActionDetailGroup>
        <ButtonActionDetailGroup.Item
          className="btn btn-primary"
          onClick={() => setShowCreateConfirmation(true)}
        >
          Create
        </ButtonActionDetailGroup.Item>
        <ButtonActionDetailGroup.Item
          className="btn btn-danger"
          onClick={() => setShowCancelConfirmation(true)}
        >
          Cancel
        </ButtonActionDetailGroup.Item>
      </ButtonActionDetailGroup>
    );
  };

  const onEPromotionCodeChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    const selectValue = e.target.checked;
    const ePromotionCode = selectValue ? instance.ePromotionCode : "";
    setInstance((inst) => {
      return {
        ...inst,
        [fieldName]: selectValue,
        ePromotionCode: ePromotionCode,
      };
    });
  };

  const renderCampaignDetails = () => {
    return (
      <div className="row">
        <div className="col">
          <Card title="Campaign Details">
            <div className=" row">
              <div className="col-md-6 mb-3">
                <FieldWrapper
                  label="Name"
                  require={true}
                  input={
                    <TextInput
                      name="name"
                      value={instance.name}
                      onChange={onInputChange}
                    />
                  }
                />
              </div>
              <div className="col-md-6 mb-3">
                <FieldWrapper
                  label="Start On"
                  require={true}
                  input={
                    <DateTimeInput
                      name="startOn"
                      value={instance.startOn}
                      onChange={onInputChange}
                      placeholder="Enter Start On"
                    />
                  }
                />
              </div>
            </div>

            <div className=" row">
              <div className="col-md-6 mb-3">
                <FieldWrapper
                  label="Code"
                  require={true}
                  input={
                    <TextInput
                      name="code"
                      value={instance.code}
                      onChange={onInputChange}
                    />
                  }
                />
              </div>
              <div className="col-md-6 mb-3">
                <FieldWrapper
                  label="End On"
                  require={true}
                  input={
                    <DateTimeInput
                      name="endOn"
                      value={instance.endOn}
                      onChange={onInputChange}
                      placeholder="Enter End On"
                    />
                  }
                />
              </div>
            </div>

            <div className=" row">
              <div className="col-md-6 mb-3">
                <FieldWrapper
                  label="Display Name"
                  require={true}
                  input={
                    <TextInput
                      name="displayName"
                      value={instance.displayName}
                      onChange={onInputChange}
                      placeholder="Enter Display Name"
                    />
                  }
                />
              </div>
              <div className="col-md-6 mb-3">
                <FieldWrapper
                  label="ePromo Code"
                  input={
                    <div className="d-flex gap-12">
                      <input
                        name="hasEPromotionCode"
                        valuetype="boolean"
                        value={instance.hasEPromotionCode}
                        onChange={onEPromotionCodeChange}
                        checked={instance.hasEPromotionCode}
                        type="checkbox"
                      />
                      <TextInput
                        name="ePromotionCode"
                        value={instance.ePromotionCode}
                        onChange={onInputChange}
                        placeholder="Enter Promotion Code"
                        disable={!instance.hasEPromotionCode}
                      />
                    </div>
                  }
                />
              </div>
            </div>

            <div className=" row">
              <div className="col-md-6 mb-3">
                <FieldWrapper
                  label="Terms &amp; Conditions"
                  require={true}
                  input={
                    <TextAreaInput
                      name="termCondition"
                      value={instance.termCondition}
                      onChange={onInputChange}
                      placeholder="Enter Terms & Condition"
                    />
                  }
                />
              </div>
              <div className="col-md-6 mb-3">
                <FieldWrapper
                  label="Description"
                  require={true}
                  input={
                    <TextAreaInput
                      name="description"
                      value={instance.description}
                      onChange={onInputChange}
                    />
                  }
                />
              </div>
            </div>
            <div className=" row">
              <div className="col-md-6 mb-3">
                <FieldWrapper
                  label="Campaign Category"
                  require={true}
                  input={
                    <SingleSelector
                      type="number"
                      name="category"
                      onChange={onInputChange}
                      value={instance.category}
                      options={CampaignCategory.All}
                    />
                  }
                />
              </div>
              <div className="col-md-6 mb-3">
                <FieldWrapper
                  label="Display Weight"
                  require={true}
                  input={
                    <NumberInput
                      name="displayWeight"
                      value={instance.displayWeight}
                      onChange={onInputChange}
                      placeholder="Enter Display Weight"
                      min={1}
                    />
                  }
                />
              </div>
            </div>
            <div className=" row">
              <div className="col-md-6 mb-3">
                <FieldWrapper
                  label="Is Stack Campaign"
                  input={
                    <CheckboxInput
                      wrapperClass="pl-0"
                      name="isStackCampaign"
                      value={instance.isStackCampaign}
                      onChange={onInputChange}
                    />
                  }
                />
              </div>
              <div className="col-md-6 mb-3">
                <FieldWrapper
                  label="Poster URL"
                  input={
                    <TextInput
                      name="posterUrl"
                      value={instance.posterUrl}
                      onChange={onInputChange}
                      placeholder="Enter Poster URL"
                    />
                  }
                  action={
                    <button
                      className="btn btn-primary float-right mt-1 mt-md-0"
                      onClick={() => setShowPreview(true)}
                      disabled={_.isEmpty(instance.posterUrl)}
                    >
                      Preview
                    </button>
                  }
                />
              </div>
            </div>
            <div className=" row">
              <div className="col-md-6 mb-3">
                <FieldWrapper
                  label="Max Redemption Quantity Per Transaction"
                  require={true}
                  input={
                    <NumberInput
                      name="maxRedemptionQuantityPerTransaction"
                      onChange={onInputChange}
                      value={instance.maxRedemptionQuantityPerTransaction}
                    />
                  }
                />
              </div>
              <div className="col-md-6 mb-3">
                <FieldWrapper
                  label="Restriction"
                  input={
                    <SingleSelector
                      type="number"
                      name="restriction"
                      enableDefaultEntry={false}
                      onChange={onInputChange}
                      value={instance.restriction}
                      options={CampaignRestriction.All}
                    />
                  }
                />
              </div>
            </div>
            <div className=" row">
              {instance.category === CampaignCategory.ExternalLoyaltyCard && (
                <div className="col-md-6 mb-3">
                  <FieldWrapper
                    label="Redemption Quantity Per Card"
                    require={true}
                    input={
                      <NumberInput
                        name="loyaltyRedemptionQuantityPerCard"
                        onChange={onInputChange}
                        value={instance.loyaltyRedemptionQuantityPerCard}
                      />
                    }
                  />
                </div>
              )}
              {instance.category === CampaignCategory.ExternalLoyaltyCard && (
                <div className="col-md-6 mb-3">
                  <FieldWrapper
                    label="Loyalty Program"
                    require={true}
                    input={
                      <TextInput
                        name="loyaltyProgram"
                        onChange={onInputChange}
                        value={instance.loyaltyProgram}
                        placeholder="Enter Loyalty Program"
                      />
                    }
                  />
                </div>
              )}
            </div>
            <div className=" row">
              {instance.category === CampaignCategory.ExternalLoyaltyCard && (
                <div className="col-md-6 mb-3">
                  <FieldWrapper
                    label="Redemption Quantity Type"
                    require={true}
                    input={
                      <SingleSelector
                        type="number"
                        name="loyaltyRedemptionQuantityType"
                        onChange={onInputChange}
                        value={instance.loyaltyRedemptionQuantityType}
                        options={RedemptionQuantityType.All}
                        placeholder="Redemption Quantity Type"
                      />
                    }
                  />
                </div>
              )}
              {instance.loyaltyRedemptionQuantityType ===
                RedemptionQuantityType.Weekly && (
                  <div className="col-md-6 mb-3">
                    <FieldWrapper
                      label="Reset Day"
                      require={true}
                      input={
                        <SingleSelector
                          type="number"
                          name="loyaltyCardResetDay"
                          onChange={onInputChange}
                          value={instance.loyaltyCardResetDay}
                          options={DayOfWeek.All}
                          placeholder="Reset Day"
                        />
                      }
                    />
                  </div>
                )}
            </div>
          </Card>
        </div>
      </div>
    );
  };

  const getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "campaigns",
        name: "Campaigns",
      }),
      activeModule: new NavigationItem({
        identifier: "campaigns",
        name: "Create Campaign",
      }),
    });
  };

  return (
    <>
      <div className="main-content">
        <BreadCrumb navigationSettings={getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            {renderCampaignDetails()}
            {renderBottomButtons()}
          </div>
        </div>
      </div>
      <PreviewImageDialog
        url={instance.posterUrl}
        visible={showPreview}
        onClose={() => setShowPreview(false)}
      />
      <ConfirmDialog
        visible={showCreateConfirmation}
        message="Are you sure you want to create?"
        onProceed={onCreate}
        onCancel={() => setShowCreateConfirmation(false)}
      />
      <ConfirmDialog
        visible={showCancelConfirmation}
        message="Are you sure you want to cancel?"
        onProceed={() => history.push("/campaigns")}
        onCancel={() => setShowCancelConfirmation(false)}
      />
    </>
  );
};
