import _, { isNil } from "lodash";
import { MovieReleaseMedia } from "./MovieReleaseMedia";
import { MovieReleaseRestriction } from "./MovieReleaseRestriction";
import moment from "moment";
import { DateHelper, StringHelper } from "../common/Helpers";
import { MovieDisplayWeight } from "../constants/MovieConstants";

const addDays = (day) => {
  const currentDay = new Date();
  currentDay.setDate(currentDay.getDate() + day);
  return currentDay;
};

const createDefaultReleaseDate = () => {
  return moment(addDays(1)).format(DateHelper.IsoDateFormat);
};

const createDefaultDisplayStartOn = () => {
  return StringHelper.toDateTimeString("2100-01-01T00:00:00");
};

const createDefaultDisplayEndOn = () => {
  return StringHelper.toDateTimeString("2100-01-02T00:00:00");
};

export class MovieRelease {
  constructor(data) {
    data = data || {};
    this.id = data.id ?? 0;
    this.primaryTitle = data.primaryTitle ?? 0;
    this.secondaryTitle = data.secondaryTitle ?? "";
    this.releaseExternalCode = data.releaseExternalCode ?? "";
    this.eventReleaseCode = data.eventReleaseCode ?? "";

    this.releaseDate = isNil(data.releaseDate)
      ? createDefaultReleaseDate()
      : moment(new Date(data.releaseDate)).format(DateHelper.IsoDateFormat);

    this.displayStartOn = isNil(data.displayStartOn)
      ? createDefaultDisplayStartOn()
      : StringHelper.toDateTimeString(data.displayStartOn);

    this.displayEndOn = isNil(data.displayEndOn)
      ? createDefaultDisplayEndOn()
      : StringHelper.toDateTimeString(data.displayEndOn);

    this.brand = data.brand ?? 0;
    this.format = data.format ?? 0;
    this.movieReleaseCountry = data.movieReleaseCountry ?? 0;
    this.movieId = data.movieId ?? 0;
    this.classify = data.classify ?? 0;
    this.subtitle = data.subtitle ?? 0;
    this.genre = data.genre ?? "";
    this.language = data.language ?? "";
    this.restriction = data.restriction;
    this.adviceName = data.adviceName ?? "";
    this.adviceOnTicket = data.adviceOnTicket ?? "";
    this.isReleaseDateConfirmed = data.isReleaseDateConfirmed ?? false;
    this.displayWeight = data.displayWeight ?? MovieDisplayWeight.Length;
    this.homePageDisplayWeight = data.homePageDisplayWeight ?? MovieDisplayWeight.Length;
    this.labelDisplay = data.labelDisplay ?? "";
    this.labelPrint = data.labelPrint ?? "";
    this.isShawExclusive = data.isShawExclusive ?? false;
    this.isPublic = data.isPublic ?? false;
    this.createdOn = data.createdOn ?? "";
    this.updatedOn = data.updatedOn ?? "";
    this.createdBy = data.createdBy ?? "";
    this.updatedBy = data.updatedBy ?? "";
    this.poster = data.posterUrl ?? "";
    this.isMarathon = data.isMarathon ?? false;
    this.isDtsx = data.isDtsx ?? false;

    if (!_.isNil(data.movieReleaseMedias)) {
      this.movieReleaseMedias = data.movieReleaseMedias.map(
        (x) => new MovieReleaseMedia(x)
      );
    } else {
      this.movieReleaseMedias = [];
    }

    if (!_.isNil(data.movieReleaseRestrictions)) {
      this.movieReleaseRestrictions = data.movieReleaseRestrictions.map(
        (x) => new MovieReleaseRestriction(x)
      );
    } else {
      this.movieReleaseRestrictions = [];
    }
  }

  validate() {
    if (!this.releaseDate) {
      return "Release Date is required.";
    }
    if (!this.eventReleaseCode) {
      return "Film Code is required.";
    }
    if (!this.releaseExternalCode) {
      return "Movie Code is required.";
    }
  }
}
