import React from "react";
import { EvoucherInformationCard } from "./cards/EvoucherInformationCard";
import {
  AuthenticationManager,
  ResourceID,
} from "../../../common/AuthenticationManager";
import { ExtensionRequestInformationCard } from "./cards/ExtensionRequestInformationCard";
import { RejectedEvoucherExtensionDialog } from "./dialogs/RejectedEvoucherExtensionDialog";
import { BreadCrumb } from "../../common/BreadCrumb";
import { StringHelper } from "../../../common/Helpers";
import { ConfirmDialog } from "../../common/ConfirmDialog";
import { EVoucherExtensionStatus } from "../../../common/Constants";
import { GenericMessages, TypeProceedRequest } from "../../../common/Constants";
import { HistoryDialog } from "../../common/HistoryDialog";
import { ObjectType } from "../../../common/Constants";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";
import { EVoucherExtensionRequestRestService } from "../../../services/EVoucherExtensionRequestRestService";

export class EvoucherExtensionRequestDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      evoucherExtensionId: "",
      evoucherExtension: null,
      extensionRecords: [],
      showRejectOrCancelDialog: false,
      showApproveDialog: false,
      showEvoucherHistoryDialog: false,
      messageDialog: "",
      titleDialog: "",
      placeholderInDialog: "",
      typeProceed: "",
      isApprove: false,
    };
    this.eVoucherExtensionRequestRestService =
      new EVoucherExtensionRequestRestService();
  }

  async componentDidMount() {
    const { id } = this.props.match.params;
    const evoucherExtensionId = StringHelper.toNumber(id, 0);
    if (evoucherExtensionId <= 0) {
      this.redirectToListingPage();
      return;
    }
    this.setState({ evoucherExtensionId });

    await this.loadEvoucherExtension(evoucherExtensionId);
    await this.loadEvoucherExtensionDetails(evoucherExtensionId);
  }

  redirectToListingPage(additionalParameter) {
    this.props.history.push(
      `/evouchers/extensions/${additionalParameter ?? ""}`
    );
  }

  loadEvoucherExtension = async (evoucherExtensionId) => {
    const response = await this.eVoucherExtensionRequestRestService.get(
      evoucherExtensionId
    );
    if (response.status === 200) {
      this.setState({ evoucherExtension: response.data });
    }
  };

  loadEvoucherExtensionDetails = async (evoucherExtensionId) => {
    const response = await this.eVoucherExtensionRequestRestService.getDetails(
      evoucherExtensionId
    );
    if (response.status === 200) {
      this.setState({ extensionRecords: response.data });
    }
  };

  onHideRejectOrCancelDialog = () => {
    this.setState({ showRejectOrCancelDialog: false });
  };

  onHideApproveDialog = () => {
    this.setState({ showApproveDialog: false });
  };

  onShowDialogConfirmClick = () => {
    this.setState({ showApproveDialog: true });
  };

  onShowRejectDialogClick = () => {
    this.setState({
      messageDialog: "Are you sure you want to reject the request?",
      placeholderInDialog: "Enter reason for rejecting request",
      typeProceed: TypeProceedRequest.Reject,
    });
    this.setState({
      showRejectOrCancelDialog: true,
    });
  };

  onShowCancelDialogClick = () => {
    this.setState({
      messageDialog: "Are you sure you want to cancel the request?",
      placeholderInDialog: "Enter reason for cancel request",
      typeProceed: TypeProceedRequest.Cancel,
    });
    this.setState({
      showRejectOrCancelDialog: true,
    });
  };

  onProceedApproveDialog = async () => {
    const { evoucherExtensionId } = this.state;
    const request = Object.assign({});
    const response = await this.eVoucherExtensionRequestRestService.approve(
      evoucherExtensionId,
      request
    );
    if (response.status === 200) {
      this.setState({
        evoucherExtension: response.data,
        showApproveDialog: false,
        isApprove: true,
      });
      toast.success(GenericMessages.SaveChangesSuccessfulMessage);
      return;
    }
    this.setState({
      showApproveDialog: false,
    });
  };

  onProceedRejectOrCancelDialog = async (reason) => {
    const { evoucherExtensionId, typeProceed } = this.state;
    const response =
      typeProceed === TypeProceedRequest.Reject
        ? await this.eVoucherExtensionRequestRestService.reject(
            evoucherExtensionId,
            { reason }
          )
        : await this.eVoucherExtensionRequestRestService.cancel(
            evoucherExtensionId,
            { reason }
          );
    if (response.status === 200) {
      this.setState({
        evoucherExtension: response.data,
        showRejectOrCancelDialog: false,
      });
      toast.success(GenericMessages.SaveChangesSuccessfulMessage);
      return;
    }
    this.setState({
      showRejectOrCancelDialog: false,
    });
  };

  onViewHistoryClick = () => {
    this.setState({ showEvoucherHistoryDialog: true });
  };

  onHideHistoryDialog = () => {
    this.setState({ showEvoucherHistoryDialog: false });
  };

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "evouchers",
        name: "eVouchers",
      }),
      activeModule: new NavigationItem({
        identifier: "evoucher-extension-request-details",
        name: "eVoucher Extension Request Details",
      }),
    });
  };

  render() {
    const {
      showApproveDialog,
      showRejectOrCancelDialog,
      messageDialog,
      placeholderInDialog,
      typeProceed,
      evoucherExtension,
      extensionRecords,
      isApprove,
      showEvoucherHistoryDialog,
    } = this.state;
    const isOwner =
      evoucherExtension &&
      evoucherExtension.createdBy === AuthenticationManager.username();
    return (
      <div className="main-content">
        <BreadCrumb navigationSettings={this.getNavigationSettings()} />
        <div className="section__content section__content--p30 py-0">
          <div className="container-fluid">
            <ButtonActionDetailGroup>
              <ButtonActionDetailGroup.Item
                className="btn btn-outline-secondary"
                onClick={this.onViewHistoryClick}
              >
                History
              </ButtonActionDetailGroup.Item>
            </ButtonActionDetailGroup>
          </div>
        </div>
        <div className="section__content section__content--p30 pt-0">
          <div className="container-fluid">
            {extensionRecords &&
              extensionRecords.map((x) => (
                <EvoucherInformationCard
                  key={x.eVoucherId}
                  evoucherId={x.eVoucherId}
                  isApprove={isApprove}
                />
              ))}
            <ExtensionRequestInformationCard
              evoucherExtension={evoucherExtension}
            />
            {evoucherExtension?.status === EVoucherExtensionStatus.Pending && (
              <ButtonActionDetailGroup>
                {AuthenticationManager.isAuthorized(
                  ResourceID.EVoucherExtensionRequestViewDetailsApproveRejectButton
                ) && (
                  <ButtonActionDetailGroup.Item
                    className="btn btn-success"
                    id="btnApprove"
                    onClick={this.onShowDialogConfirmClick}
                  >
                    Approve
                  </ButtonActionDetailGroup.Item>
                )}
                {AuthenticationManager.isAuthorized(
                  ResourceID.EVoucherExtensionRequestViewDetailsApproveRejectButton
                ) && (
                  <ButtonActionDetailGroup.Item
                    className="btn btn-danger"
                    id="btnReject"
                    onClick={() => this.onShowRejectDialogClick()}
                  >
                    Reject
                  </ButtonActionDetailGroup.Item>
                )}
                {AuthenticationManager.isAuthorized(
                  ResourceID.EVoucherExtensionRequestViewDetailsCancelButton
                ) &&
                  isOwner && (
                    <ButtonActionDetailGroup.Item
                      className="btn btn-secondary"
                      id="btnCancel"
                      onClick={() => this.onShowCancelDialogClick()}
                    >
                      Cancel
                    </ButtonActionDetailGroup.Item>
                  )}
              </ButtonActionDetailGroup>
            )}
          </div>
        </div>
        <ConfirmDialog
          visible={showApproveDialog}
          onCancel={this.onHideApproveDialog}
          onProceed={this.onProceedApproveDialog}
          message="You are about to approve the evoucher extension request. Please confirm to proceed."
          title="Confirmation"
        />
        {showRejectOrCancelDialog && (
          <RejectedEvoucherExtensionDialog
            typeProceed={typeProceed}
            message={messageDialog}
            placeholderText={placeholderInDialog}
            onCancel={this.onHideRejectOrCancelDialog}
            onProceed={this.onProceedRejectOrCancelDialog}
          />
        )}
        {showEvoucherHistoryDialog && (
          <HistoryDialog
            objectId={evoucherExtension.id}
            onClose={this.onHideHistoryDialog}
            url="/api/v1/history_logs"
            objectType={ObjectType.EvoucherExtensionRequest}
          />
        )}
      </div>
    );
  }
}
