import React from "react";
import { BreadCrumb } from "../../common/BreadCrumb";
import { EvoucherInformationCard } from "./cards/EvoucherInformationCard";
import { RedemptionDetailsCard } from "./cards/RedemptionDetailsCard";
import { ExtendValidityDialog } from "./dialogs/ExtendValidityDialog";
import { BaseConfirmReasonDialog } from "../common/BaseConfirmReasonDialog";
import { StringHelper } from "../../../common/Helpers";
import RestClient from "../../../common/RestClient";
import { GenericMessages } from "../../../common/Constants";
import {
  EVoucherStatus,
  EVoucherExtensionStatus,
} from "../../../common/Constants";
import {
  AuthenticationManager,
  ResourceID,
} from "../../../common/AuthenticationManager";
import { HistoryDialog } from "../../common/HistoryDialog";
import {
  NavigationItem,
  NavigationSettings,
} from "../../common/NavigationSettings";
import { toast } from "react-toastify";
import { ObjectType } from "../../../common/Constants";
import ButtonActionDetailGroup from "../../common/ButtonActionDetailGroup";

export class EvoucherDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      evoucher: null,
      isShowExtendValidityDialog: false,
      isSubmitted: false,
      isShowBlockDialog: false,
      isExtendedValidity: false,
      evoucherExtension: null,
      showEvoucherHistoryDialog: false,
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const evoucherId = StringHelper.toNumber(id, 0);
    if (evoucherId > 0) {
      this.setState({ evoucherId }, this.loadEVoucher);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params !== this.props.match.params) {
      const { id } = this.props.match.params;
      const evoucherId = StringHelper.toNumber(id, 0);
      if (evoucherId > 0) {
        this.loadEVoucher();
      }
    }
  }

  loadEVoucher() {
    const { id } = this.props.match.params;
    RestClient.sendGetRequest(
      `/api/v1/evouchers/${id}`,
      (response) => {
        this.setState({ evoucher: response });
        this.loadEvoucherExtensionRequest(response.id);
      },
      (error) => {
        toast.error(error.message);
      }
    );
  }

  loadEvoucherExtensionRequest = (evoucherId) => {
    const queryParameter = {
      evoucherId: evoucherId,
      requestStatus: EVoucherExtensionStatus.Pending,
    };
    RestClient.sendGetRequestWithParameters(
      `/api/v1/evoucher_extensions/`,
      queryParameter,
      (response) => {
        if (response?.data?.length > 0) {
          this.setState({
            isExtendedValidity: true,
            evoucherExtension: response.data[0],
          });
        }
      }
    );
  };

  extend = (extendRequest) => {
    RestClient.sendPostRequest(
      `/api/v1/evoucher_extensions/`,
      extendRequest,
      (response) => {
        this.setState({
          isExtendedValidity: true,
          evoucherExtension: response,
        });
        this.toggleExtendValidityDialog(false);
        toast.success(
          GenericMessages.CreationExtensionEvoucherSuccessfullMessage
        );
      },
      (error) => {
        toast.error(error.message);
      }
    );
  };
  toggleExtendValidityDialog = (visible) => {
    this.setState({ isShowExtendValidityDialog: visible });
  };
  redirectToRefundEVoucher = (refundEVoucherId) => {
    this.props.history.push(`/evouchers/evoucher/${refundEVoucherId}`);
  };

  redirectToOriginEVoucher = (originEVoucherId) => {
    this.props.history.push(`/evouchers/evoucher/${originEVoucherId}`);
  };

  redirectToEVoucherExtension = (evocherExtensionId) => {
    this.props.history.push(`/evouchers/extensions/${evocherExtensionId}`);
  };

  onViewHistoryClick = () => {
    this.setState({ showEvoucherHistoryDialog: true });
  };

  onHideHistoryDialog = () => {
    this.setState({ showEvoucherHistoryDialog: false });
  };

  getNavigationSettings = () => {
    return new NavigationSettings({
      parentModule: new NavigationItem({
        identifier: "evouchers",
        name: "eVouchers",
      }),
      activeModule: new NavigationItem({
        identifier: "evoucher-details",
        name: "eVoucher Details",
      }),
    });
  };

  render() {
    const {
      evoucher,
      isShowExtendValidityDialog,
      isSubmitted,
      isShowBlockDialog,
      isExtendedValidity,
      evoucherExtension,
      showEvoucherHistoryDialog,
    } = this.state;
    return (
      <div className="main-content">
        <BreadCrumb navigationSettings={this.getNavigationSettings()} />
        <div className="section__content section__content--p30">
          <div className="container-fluid">
            <ButtonActionDetailGroup>
              {evoucher?.status === EVoucherStatus.Active &&
                evoucher?.isRefundEVoucher && (
                  <ButtonActionDetailGroup.Item
                    className="btn btn-primary"
                    onClick={() =>
                      this.redirectToOriginEVoucher(evoucher.originEVoucherId)
                    }
                  >
                    View Origin eVoucher
                  </ButtonActionDetailGroup.Item>
                )}
              {evoucher?.status === EVoucherStatus.Refunded && (
                <ButtonActionDetailGroup.Item
                  className="btn btn-primary"
                  onClick={() =>
                    this.redirectToRefundEVoucher(evoucher.refundEVoucherId)
                  }
                >
                  View Refund eVoucher
                </ButtonActionDetailGroup.Item>
              )}
              {(evoucher?.status === EVoucherStatus.Active ||
                evoucher?.status === EVoucherStatus.Expired) &&
                !isExtendedValidity &&
                AuthenticationManager.isAuthorized(
                  ResourceID.EVoucherViewDetailsExtendValidityButton
                ) && (
                  <ButtonActionDetailGroup.Item
                    className="btn btn-primary"
                    onClick={() => this.toggleExtendValidityDialog(true)}
                  >
                    Extend validity
                  </ButtonActionDetailGroup.Item>
                )}
              {isExtendedValidity && (
                <ButtonActionDetailGroup.Item
                  className="btn btn-primary"
                  onClick={() =>
                    this.redirectToEVoucherExtension(evoucherExtension.id)
                  }
                >
                  View Pending Request
                </ButtonActionDetailGroup.Item>
              )}
              <ButtonActionDetailGroup.Item
                className="btn btn-outline-secondary"
                onClick={this.onViewHistoryClick}
              >
                History
              </ButtonActionDetailGroup.Item>
            </ButtonActionDetailGroup>
            {evoucher && <EvoucherInformationCard eVoucher={evoucher} />}
            {evoucher?.status === EVoucherStatus.Used && (
              <RedemptionDetailsCard eVoucherId={evoucher.id} />
            )}
          </div>
        </div>
        {isShowExtendValidityDialog && (
          <ExtendValidityDialog
            currentExpiredDate={evoucher.validityEnd}
            isSubmitted={isSubmitted}
            eVoucherId={evoucher.id}
            onCancel={() => this.toggleExtendValidityDialog(false)}
            onProceed={this.extend}
            reasonType={"Reason:"}
            placeholderText={"Enter reason for evoucher extension"}
          />
        )}
        {showEvoucherHistoryDialog && (
          <HistoryDialog
            objectId={evoucher.id}
            onClose={this.onHideHistoryDialog}
            url="/api/v1/history_logs"
            objectType={ObjectType.Evoucher}
          />
        )}
      </div>
    );
  }
}
