import React, { useCallback, useEffect, useState } from "react";
import RestClient from "../../../../common/RestClient";
import "../../../css/images.css";
import "./styles.scss";
import { toast } from "react-toastify";
import { Card } from "../../../common/Card";
import ButtonActionDetailGroup from "../../../common/ButtonActionDetailGroup";
import {
  SeatSelection,
  SeatSelectionMode,
} from "../../../../common/SeatingLayout";

const loadScript = (url) => {
  const existingScripts = document.querySelectorAll(`script[src="${url}"]`);
  if (existingScripts.length) return Promise.resolve(true);
  return new Promise((resolve) => {
    const script = document.createElement("script");
    document.body.appendChild(script);
    if (script.readyState) {
      // only required for IE <9
      script.onreadystatechange = () => {
        if (
          script.readyState === "loaded" ||
          script.readyState === "complete"
        ) {
          script.onreadystatechange = null;
          resolve(true);
        }
      };
    } else {
      // other browsers
      script.onload = () => resolve(true);
    }
    script.src = url;
  });
};
export const SeatPlanComponent = (props) => {
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [buttonMode, setButtonMode] = useState(SeatSelectionMode.SelectSeat);
  const [seatSelection, setSeatSelection] = useState(null);

  const {
    guestCount,
    onChangeSeatCodes,
    readOnly,
    preSelectedSeats,
    performanceId,
    reload,
    remainingCapacity
  } = props;

  useEffect(() => {
    if (!seatSelection) return;
    seatSelection.build(preSelectedSeats);
    seatSelection.readOnly = () => !!readOnly;
    seatSelection.onSeatChange = onSeatChange;
    seatSelection.canHoldSeat = isSelectable;
    const selectedSeats = seatSelection.getSelectedSeats();
    const onHoldSeats = seatSelection.getOnHoldSeats();
    const availableSeats = seatSelection.getAvailableSeats();
    onSetChangedSeats({
      selectedSeats,
      onHoldSeats,
      availableSeats,
    });
    setSelectedSeats(selectedSeats);
    // Dependencies with only seatSelection to prevent infinite loop due to seating layout initializing
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seatSelection]);

  useEffect(() => {
    if (!seatSelection) return;
    seatSelection.clearAllSeats();
    onLoad();
    // Dependencies with only reload to prevent infinite loop due to seating layout initializing
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  useEffect(() => {
    if (!seatSelection) return;
    seatSelection.readOnly = () => readOnly;
  }, [readOnly, seatSelection]);

  const onLoad = () => {
    if (typeof window.$ === "function" && typeof window.ej !== "undefined") {
      RestClient.sendGetRequestWithParameters(
        "/api/v1/layouts",
        {
          performanceId: performanceId,
          maxResults: 99999,
        },
        (results) => {
          if (!results.data.length) {
            toast.error("No layout config for this performance.");
            return;
          }
          setSeatSelection((prev) => {
            if (prev) {
              prev.destroy();
            }
            return new SeatSelection(
              "DiagramTest",
              performanceId,
              results.data
            );
          });
        },
        (error) => toast.error(error.message)
      );
    }
  };

  useEffect(() => {
    if (!performanceId) return;
    Promise.all([
      loadScript("/js/jquery.js"),
      loadScript("/js/ej.tooltip.diagram.all.min.js"),
      loadScript("/js/ej2.diagram.min.js"),
    ]).then(() => {
      onLoad();
    });
    // Dependencies with only performanceId to prevent infinite loop due to seating layout initializing
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [performanceId]);

  const onSetChangedSeats = useCallback(
    (event) => {
      const onChangeSeatCodes = props.onChangeSeatCodes;
      if (onChangeSeatCodes) {
        onChangeSeatCodes(event);
      }
    },
    [props.onChangeSeatCodes]
  );

  const onSeatChange = useCallback(() => {
    const selectedSeats = seatSelection.getSelectedSeats();
    const availableSeats = seatSelection.getAvailableSeats();
    if (selectedSeats.length > guestCount) {
      toast.error(`The selected seats exceeded the guest count ${guestCount}.`);
    }
    setSelectedSeats(selectedSeats);
    onSetChangedSeats({ availableSeats, selectedSeats });
  }, [guestCount, onSetChangedSeats, seatSelection]);

  useEffect(() => {
    setSeatSelection((prev) => {
      if (!prev) return prev;
      prev.onSeatChange = onSeatChange;
      return prev;
    });
  }, [guestCount, onSeatChange]);

  const isSelectable = () => {
    const { canSelectSeat, onChangeSeatCodes } = props;
    if (!onChangeSeatCodes || !canSelectSeat) {
      return false;
    }
    return canSelectSeat();
  };

  const getButtonMode = (mode) => {
    return buttonMode === mode ? SeatSelectionMode.SelectSeat : mode;
  };

  const toggleReleaseHouseSeat = () => {
    const buttonMode = getButtonMode(SeatSelectionMode.ReleaseHouseSeat);
    setButtonMode(buttonMode);
    seatSelection.setSelectionMode(buttonMode);
    let message = "Disabled house seats releasing functionality";
    if (buttonMode === SeatSelectionMode.ReleaseHouseSeat) {
      message = "Enabled house seats releasing functionality";
    }
    toast.info(message);
  };

  const toggleBlockHouseSeat = () => {
    const buttonMode = getButtonMode(SeatSelectionMode.BlockHouseSeat);
    setButtonMode(buttonMode);
    seatSelection.setSelectionMode(buttonMode);
    seatSelection.toggleHighLightBlockableHouseSeat(
      seatSelection.isBlockHouseSeatMode()
    );
    let message = "Disabled house seats blocking functionality";
    if (buttonMode === SeatSelectionMode.BlockHouseSeat) {
      message = "Enabled house seats blocking functionality";
    }
    toast.info(message);
  };

  const onAutoSelect = () => {
    if (!seatSelection) {
      return;
    }
    if (guestCount <= 0) {
      toast.error("The guest count is not specified yet.");
      return;
    }
    if (guestCount > remainingCapacity) {
      toast.error(`The guest count must be less than ${remainingCapacity}.`);
      return;
    }
    seatSelection.autoSelect(guestCount);
    onSeatChange();
  };

  const onClearAll = () => {
    if (!seatSelection) {
      return;
    }
    seatSelection.clearAllSeats();
    onSeatChange();
  };

  const isSelectMode = buttonMode === SeatSelectionMode.SelectSeat;
  const isBlockHouseSeatMode = buttonMode === SeatSelectionMode.BlockHouseSeat;
  const isReleaseHouseMode = buttonMode === SeatSelectionMode.ReleaseHouseSeat;
  
  const totalSelectAvailableSeat = Math.min(
    guestCount,
    remainingCapacity
  );
  return (
    <div className="row">
      <div className="col">
        <Card title="Seat Selection">
          <div id="DiagramTest" />
          <div className="cinema-footer-info counter-view mt-2">
            <div className="row row-seat-actions h-100">
              <div className="col-md-8">
                <div>
                  <p>
                    Seat Count Selected: {selectedSeats.length} /{" "}
                    {totalSelectAvailableSeat}
                  </p>
                  <div className="seat-icons non-IE7 hide-1024">
                    <span className="gray">Legend</span>
                    <div className="sprite-seating-seat-curr-avail" />
                    Available
                    <div className="sprite-seating-seat-curr-select" /> Selected
                    <div className="sprite-seating-seat-curr-unavail" />{" "}
                    Unavailable
                    <div className="sprite-seating-seat-curr-hold" /> Assigned
                    <div className="sprite-seating-seat-curr-wheelchair-avail" />{" "}
                    Wheelchair
                  </div>
                </div>
              </div>
              {!readOnly && onChangeSeatCodes && (
                <div className="col-md-4">
                  <ButtonActionDetailGroup>
                    <ButtonActionDetailGroup.Item
                      className={
                        isBlockHouseSeatMode
                          ? "btn btn-success"
                          : "btn btn-primary"
                      }
                      onClick={toggleBlockHouseSeat}
                      disabled={isReleaseHouseMode}
                    >
                      Block House Seat
                    </ButtonActionDetailGroup.Item>
                    <ButtonActionDetailGroup.Item
                      className={
                        isReleaseHouseMode
                          ? "btn btn-success"
                          : "btn btn-primary"
                      }
                      onClick={toggleReleaseHouseSeat}
                      disabled={isBlockHouseSeatMode}
                    >
                      Release House Seat
                    </ButtonActionDetailGroup.Item>
                    <ButtonActionDetailGroup.Item
                      className="btn btn-primary"
                      onClick={onAutoSelect}
                      disabled={!isSelectMode}
                    >
                      Auto Select
                    </ButtonActionDetailGroup.Item>
                    <ButtonActionDetailGroup.Item
                      className="btn btn-danger"
                      onClick={onClearAll}
                      disabled={!isSelectMode}
                    >
                      Clear All
                    </ButtonActionDetailGroup.Item>
                  </ButtonActionDetailGroup>
                </div>
              )}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};
