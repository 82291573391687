import $ from "jquery";
import React, { Component } from "react";
import RestClient from "../../common/RestClient";
import { BreadCrumb } from "./BreadCrumb";
import { Constants } from "./Constant";
import { NavigationSettings } from "./NavigationSettings";
import _ from "lodash";
import { toast } from "react-toastify";

export class BaseListing extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitState();
    this.currentPage = 0;
    this.numberOfPage = 0;
    this.pageBound = Constants.MaxNumberOfPagination;
    this.upperPageBound = Constants.MaxNumberOfPagination;
    this.lowerPageBound = 0;
    this.sortBy = "";
    this.searchBy = {};
  }

  getInitState() {
    return {
      data: [],
      total: 0,
      pageSize: 0,
      isSort: false,
    };
  }

  componentDidMount() {
    this.loadData(0);
    setTimeout(() => {
      this.setDefaultColumnSort();
    }, 200);
  }

  getQueryParamValue = (queryParam, defaultValue = "") => {
    const url = new URL(window.location.href);
    return url.searchParams.get(queryParam) || defaultValue;
  };

  setDefaultColumnSort() {
    const defaultSort = this.getDefaultSort();
    if (!defaultSort) return;
    const { sign, columnName } = this.getSignAndColumnName(defaultSort);
    const column = $(`#${columnName}`);
    column.addClass(this.getColumnSortClass(sign));
  }

  getSignAndColumnName(sort) {
    const result = {
      sign: "+",
      columnName: "",
    };
    const matched = sort.match(/^[-+]/);
    if (matched?.length) {
      result.sign = sort.substring(0, 1);
      result.columnName = sort.substring(1);
    } else {
      result.columnName = sort;
    }
    return result;
  }

  getColumnSortClass(sign) {
    return `color-light-blue ${
      sign === "-" ? "fa-sort-alpha-down" : "fa-sort-alpha-down-alt"
    }`;
  }

  getNavigationSettings = () => {
    return new NavigationSettings({});
  };

  getDefaultSort = () => "";

  getApiPath = () => "";

  search = () => {
    this.resetPageBounds();
    this.loadData(0);
  };

  loadData(pageIndex, numberOfShowing, sortBy, searchBy) {
    this.currentPage = pageIndex;
    const pageSize =
      !numberOfShowing || numberOfShowing <= 0
        ? this.getPageSize()
        : numberOfShowing;
    let queryParameters = {
      startAt: pageIndex * pageSize,
      maxResults: pageSize,
    };
    queryParameters = this.injectSortTerm(queryParameters, sortBy);
    queryParameters = this.injectSearchTerm(queryParameters, searchBy);
    RestClient.sendGetRequestWithParameters(
      this.getApiPath(),
      queryParameters,
      (response) => {
        this.onConsumeResponse(response);
      },
      this.handleError
    );
  }

  onConsumeResponse(response) {
    this.setState({
      data: response.data,
      total: response.total,
    });
  }

  generateTableFilter() {}

  generateTableContent() {}

  generateExtendedComponents() {}

  generateModals() {}

  generateBottomButtons() {}

  injectSortTerm(queryParameters, sortBy) {
    if (sortBy != null) {
      queryParameters.sortColumn = sortBy;
      this.sortBy = sortBy;
    } else {
      queryParameters.sortColumn = this.sortBy
        ? this.sortBy
        : this.getDefaultSort();
    }
    return queryParameters;
  }

  injectSearchTerm(queryParameters, searchBy) {
    if (searchBy) {
      Object.entries(searchBy).forEach(([key, value]) => {
        if (!Number.isNaN(value) && value < 0) {
          return;
        }
        if (!_.isNil(value)) {
          queryParameters[key] = value;
        }
      });
    }

    return this.standardizeQueryParams(queryParameters);
  }

  standardizeQueryParams(obj) {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => !!value)
    );
  }

  handleError = (error) => {
    toast.error(error.title ? error.title : error.message);
  };

  onRowClick = (id) => {
    const { activeModule } = this.getNavigationSettings();
    this.props.history.push(`${activeModule.identifier}/${id}`);
  };

  render() {
    return (
      <>
        <div className="main-content">
          <BreadCrumb navigationSettings={this.getNavigationSettings()} />
          <div className="section__content section__content--p30">
            <div className="container-fluid">
              {this.generateExtendedComponents()}
              {this.generateMainGrid()}
              {this.generateBottomButtons()}
            </div>
          </div>
        </div>
        {this.generateModals()}
      </>
    );
  }

  getPageSize() {
    const { pageSize } = this.state;
    return pageSize && pageSize > 0 ? pageSize : Constants.PageSizes[0];
  }

  generateMainGrid() {
    const { total } = this.state;
    if (_.isNil(total)) {
      return null;
    }
    return (
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col">{this.generateTableFilter()}</div>
          </div>
          <div className="row">
            <div className="col">
              <div className="table-responsive m-b-10 table-px-15">
                <table className="table table-borderless table-striped table-earning">
                  {this.generateTableContent()}
                </table>
              </div>
            </div>
          </div>
          {total === 0 && (
            <p className="d-flex justify-content-center">No results</p>
          )}
          {this.generatePagination()}
        </div>
      </div>
    );
  }

  generatePagination() {
    const elements = [];
    const pageSize = this.getPageSize();
    this.numberOfPages = Math.floor(this.state.total / pageSize);
    let residual = this.state.total % pageSize;
    if (residual > 0) {
      this.numberOfPages++;
    }
    for (
      let index = this.lowerPageBound;
      index < this.upperPageBound && index < this.numberOfPages;
      index++
    ) {
      let isActivePage = this.currentPage === index;
      elements.push(
        <li
          className={isActivePage ? "page-item active" : "page-item"}
          key={index}
        >
          <a
            className="page-link"
            href="#"
            onClick={(e) => this.onSelectPage(e, index)}
          >
            {index + 1}
          </a>
        </li>
      );
    }
    return (
      <div className="d-flex justify-content-between">
        <div className="col-md-5 d-none d-md-block pl-0" aria-label="Page nav">
          <ul className="pagination">
            <li className="page-item">
              <a
                className="page-link"
                href="#"
                aria-label="Previous"
                onClick={this.onPrevious}
              >
                <span aria-hidden="true">«</span>
                <span className="sr-only">Previous</span>
              </a>
            </li>

            {this.lowerPageBound > 0 && (
              <li className="page-item">
                <a
                  className="page-link"
                  href="#"
                  onClick={this.btnDecrementClick}
                >
                  <span> &hellip;</span>
                </a>
              </li>
            )}

            {elements}

            {this.numberOfPages > this.upperPageBound && (
              <li className="page-item">
                <a
                  className="page-link"
                  href="#"
                  onClick={this.btnIncrementClick}
                >
                  <span> &hellip;</span>
                </a>
              </li>
            )}

            <li className="page-item">
              <a
                className="page-link"
                href="#"
                aria-label="Next"
                onClick={this.onNext}
              >
                <span aria-hidden="true">»</span>
                <span className="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </div>
        <div className="d-inline-flex d-block d-md-none">
          <select
            className="form-control float-right"
            onChange={(e) => this.onSelectPage(e, e.target.value)}
            value={this.currentPage}
          >
            {Array.from({ length: this.numberOfPages }).map((_, index) => {
              return (
                <option value={index} key={index}>
                  Page {index + 1}
                </option>
              );
            })}
          </select>
        </div>
        <div className="d-inline-flex">
          <select
            className="form-control float-right"
            onChange={this.onSelectShowingNumber}
            value={this.state.pageSize}
          >
            {Constants.PageSizes.map((item, index) => {
              return (
                <option value={item} key={index}>
                  Showing {item} rows
                </option>
              );
            })}
          </select>
        </div>
      </div>
    );
  }

  toggleSort = async (e) => {
    let target = e.target;
    this.resetSortedColumn(target);
    this.setState({
      isSort: !this.state.isSort,
    });
    await this.loadData(
      this.currentPage,
      this.getPageSize(),
      this.getSortBy(target)
    );
  };

  resetSortedColumn(target) {
    let sortButtons = $(".sort-button");
    for (const sortButton of sortButtons) {
      let element = $(sortButton);
      if ($(target).attr("id") === element.attr("id")) {
        continue;
      }

      if (element.hasClass("color-light-blue")) {
        element.removeClass("color-light-blue");
      }

      if (element.hasClass("fa-sort-alpha-down-alt")) {
        element.removeClass("fa-sort-alpha-down-alt");
      }

      if (!element.hasClass("fa-sort-alpha-down")) {
        element.addClass("fa-sort-alpha-down");
      }
    }
  }

  getSortBy(target) {
    const columnName = $(target).attr("id");
    if (!$(target).hasClass("fa-sort-alpha-down")) {
      $(target)
        .removeClass("color-light-blue")
        .removeClass("fa-sort-alpha-down-alt")
        .addClass("fa-sort-alpha-down");
      return "";
    }

    if ($(target).hasClass("color-light-blue")) {
      $(target)
        .removeClass("fa-sort-alpha-down")
        .addClass("fa-sort-alpha-down-alt");
      return `+${columnName}`;
    }

    $(target).addClass("color-light-blue");
    return `-${columnName}`;
  }

  onColumnClick = (e) => {
    const icon = e.target.firstElementChild;
    if (_.isNil(icon)) {
      return;
    }
    icon.click();
  };

  onKeyDownSearchInput = async (e) => {
    if (e.keyCode === 13) {
      await this.search();
    }
  };

  onSelectPage = async (e, pageNumber) => {
    e.preventDefault();
    this.currentPage = pageNumber;
    this.loadData(pageNumber);
  };

  onSelectShowingNumber = (e) => {
    let showingNumber = Number(e.target.value);
    if (isNaN(showingNumber)) {
      return;
    }
    this.setState({ pageSize: showingNumber });
    this.loadData(0, showingNumber);
  };

  btnIncrementClick = async (e) => {
    const page = this.upperPageBound;
    this.upperPageBound += this.pageBound;
    this.lowerPageBound += this.pageBound;
    await this.onSelectPage(e, page);
  };

  btnDecrementClick = async (e) => {
    const page = this.lowerPageBound - this.pageBound;
    this.upperPageBound -= this.pageBound;
    this.lowerPageBound -= this.pageBound;
    await this.onSelectPage(e, page);
  };

  resetPageBounds() {
    this.lowerPageBound = 0;
    this.upperPageBound = this.pageBound;
    this.currentPage = 0;
  }

  onNext = async (e) => {
    e.preventDefault();
    let nextPage = this.currentPage + 1;
    if (nextPage === this.numberOfPages) {
      return;
    }
    if (nextPage >= this.upperPageBound) {
      this.upperPageBound += this.pageBound;
      this.lowerPageBound += this.pageBound;
    }

    await this.onSelectPage(e, nextPage);
  };

  onPrevious = async (e) => {
    e.preventDefault();
    let previousPage = this.currentPage - 1;
    if (previousPage < 0) {
      return;
    }
    if (previousPage < this.lowerPageBound) {
      this.upperPageBound = this.upperPageBound - this.pageBound;
      this.lowerPageBound = this.lowerPageBound - this.pageBound;
    }

    await this.onSelectPage(e, previousPage);
  };
}
