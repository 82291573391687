import moment from "moment";

import { PasswordPolicies } from "./Constants";
import { StorageModule } from "../common/Constants";

export class ArrayHelper {
  static divide(array, numberOfSubArrays) {
    const elCount = Math.ceil(array.length / numberOfSubArrays);
    let results = [];
    for (let i = 0; i < numberOfSubArrays; i++) {
      let subResults = [];
      for (let j = 0; j < elCount; j++) {
        const idx = i * elCount + j;
        if (idx < array.length) {
          subResults.push(array[idx]);
        }
      }
      results.push(subResults);
    }
    return results;
  }

  static isNullOrEmpty(array) {
    return (
      array === undefined ||
      array === null ||
      (typeof array === "array" && array.length === 0)
    );
  }
}

export class DateHelper {
  static DisplayDateFormat = "DD/MM/YYYY";
  static DisplayDateTimeFormat = "DD/MM/YYYY HH:mm A";
  static DisplayDatePlaceholder = "dd/mm/yyyy";
  static DisplayDateTimePlaceholder = "dd/mm/yyyy --:-- --";
  static MembershipCardDateFormat = "DD MMMM YYYY";
  static DisplayTimeFormat = "HH:mm A";
  static DisplayShortTimeFormat = "HH:mm";
  static IsoDateFormat = "yyyy-MM-DD";
  static IsoDateTimeFormat = "yyyy-MM-DDTHH:mm:ss";
  static DisplayFullDateTimeFormat = "yyyy-MM-DD HH:mm A";

  static toDisplayFullDateFormat(date) {
    if (!date) {
      return "";
    }
    return moment(date).format(DateHelper.DisplayFullDateTimeFormat);
  }

  static toDisplayDateFormat(date) {
    if (!date) {
      return "";
    }
    return moment(date).format(DateHelper.DisplayDateFormat);
  }

  static toDisplayShortTimeFormat(date) {
    if (!date) {
      return "";
    }
    return moment(date).format(DateHelper.DisplayShortTimeFormat);
  }

  static toDisplayTimeFormat(date) {
    if (!date) {
      return "";
    }
    return moment(date).format(DateHelper.DisplayTimeFormat);
  }

  static toDisplayDateTimeFormat(date) {
    if (!date) {
      return "";
    }
    return moment(date).format(DateHelper.DisplayDateTimeFormat);
  }

  static toMembershipCardDateFormat(value) {
    return moment(new Date(value)).format(DateHelper.MembershipCardDateFormat);
  }

  static toIsoDateFormat(date) {
    if (!date) {
      return "";
    }
    return moment(date).format(DateHelper.IsoDateFormat);
  }

  static toIsoDateTimeFormat(date) {
    return new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    ).toISOString();
  }

  static formatDateTimeString(value) {
    if (!value) {
      return "";
    }
    return moment(new Date(value)).format(DateHelper.DisplayDateTimeFormat);
  }

  static formatDateTime(value) {
    return moment(value).format(DateHelper.DateTimeFormat);
  }

  static formatDateTimeInput(value) {
    return moment(value).format(DateHelper.InputDateTimeFormat);
  }
}

export class EnumHelper {
  static hasFlag(flag, flagValue) {
    const validFlagValues = [1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024];
    if (
      isNaN(flag) ||
      isNaN(flagValue) ||
      flag <= 0 ||
      validFlagValues.indexOf(flagValue) === -1
    ) {
      return false;
    }
    const flagValues = [];
    let remainder = flag;
    for (let i = validFlagValues.length - 1; i >= 0; i--) {
      if (remainder >= validFlagValues[i]) {
        remainder -= validFlagValues[i];
        flagValues.push(validFlagValues[i]);
      }
    }
    return flagValues.indexOf(flagValue) > -1;
  }
}

export class ImageHelper {
  static getPosterUrl(url) {
    return StringHelper.isNullOrEmpty(url) ? ImageHelper.noImageUrl() : url;
  }

  static noImageUrl() {
    return "https://shaw.sg/Content/Images/common/default/en-SG/noimage.png";
  }

  static onPosterError(e) {
    e.target.src = ImageHelper.noImageUrl();
  }
}

export class NumberHelper {
  static formatRuntime(runtime) {
    if (isNaN(runtime)) {
      return "";
    }
    const rt = Number(runtime);
    const h = Math.floor(rt / 60);
    const m = rt % 60;
    return `${h}h ${m}mins`;
  }
}

export class StringHelper {
  static asDateStringToDefaultFormat(dateStr) {
    if (StringHelper.isNullOrEmpty(dateStr)) {
      return "";
    }
    const date = new Date(dateStr);
    return Intl.DateTimeFormat("en-SG").format(date);
  }

  static asDateStringToDisplayDateFormat(dateStr) {
    const normalizedDateStr = StringHelper.trimTimezone(dateStr);
    if (!normalizedDateStr) {
      return "";
    }
    return DateHelper.toDisplayDateFormat(new Date(normalizedDateStr));
  }

  static asDateStringToDisplayDateTimeFormat(dateStr) {
    const normalizedDateStr = StringHelper.trimTimezone(dateStr);
    if (!normalizedDateStr) {
      return "";
    }
    return DateHelper.toDisplayDateTimeFormat(
      new Date(StringHelper.trimTimezone(normalizedDateStr))
    );
  }

  static asDateStringToMembershipCardDateFormat(dateStr) {
    const normalizedDateStr = StringHelper.trimTimezone(dateStr);
    if (!normalizedDateStr) {
      return "";
    }
    return DateHelper.toMembershipCardDateFormat(new Date(dateStr));
  }

  static asDateStringToIsoDateFormat(dateStr) {
    const normalizedDateStr = StringHelper.trimTimezone(dateStr);
    if (!normalizedDateStr) {
      return "";
    }
    return DateHelper.toIsoDateFormat(new Date(normalizedDateStr));
  }

  static asDateStringToIsoDateTimeFormat(dateStr) {
    const normalizedDateStr = StringHelper.trimTimezone(dateStr);
    if (!normalizedDateStr) {
      return "";
    }
    return DateHelper.toIsoDateTimeFormat(new Date(normalizedDateStr));
  }

  static equalsIgnoreCase(string1, string2) {
    if (
      !StringHelper.isNullOrEmpty(string1) &&
      !StringHelper.isNullOrEmpty(string2)
    ) {
      return string1.toLowerCase() === string2.toLowerCase();
    }
    return string1 === string2;
  }

  static format(text) {
    if (arguments.length <= 1) {
      return text;
    }
    let tokenCount = arguments.length - 2;
    for (let token = 0; token <= tokenCount; token++) {
      text = text.replace(
        new RegExp("\\{" + token + "\\}", "gi"),
        arguments[token + 1]
      );
    }
    return text;
  }

  static isNullOrEmpty(text) {
    return text === undefined || text === null || text === "";
  }

  static isPasswordValid(password) {
    const validLength =
      password.length >= PasswordPolicies.MinLength &&
      password.length <= PasswordPolicies.MaxLength;
    const hasDigit = !PasswordPolicies.HasDigit || /[0-9]/.test(password);
    const hasLowercase =
      !PasswordPolicies.HasLowercase || /[a-z]/.test(password);
    const hasUppercase =
      !PasswordPolicies.HasUppercase || /[A-Z]/.test(password);
    return validLength && hasDigit && hasLowercase && hasUppercase;
  }

  static randomPassword(length) {
    let pwd = StringHelper.randomString(length);
    while (!StringHelper.isPasswordValid(pwd)) {
      pwd = StringHelper.randomString(length);
    }
    return pwd;
  }

  static randomString(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  static toDateTimeString(dateStr) {
    return StringHelper.isNullOrEmpty(dateStr) ? null : dateStr;
  }

  static toNumber(numberStr, fallbackValue) {
    return isNaN(numberStr) ? fallbackValue : Number(numberStr);
  }

  static isEmail(email) {
    if (!email) {
      return false;
    }
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  }

  static handleize(text, replaceChar = "-") {
    text = text
      .toLowerCase()
      .replace(/\s+/g, replaceChar)
      .replace(/[^\w\-]+/g, "")
      .replace(/\-\-+/g, replaceChar)
      .replace(/^-+/, "")
      .replace(/-+$/, "");

    return text;
  }

  // TODO temporary solution to prevent UTC datetime from server when creating new object
  static trimTimezone(str) {
    if (!str || !str.includes("Z")) return str;
    return str.replace("T", " ").replace("Z", "");
  }

  static isValidUrl(url) {
    if (!url) {
      return false;
    }
    const regex =
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

    return regex.test(String(url).toLowerCase());
  }
}

export class ObjectHelper {
  static getPropertyName = (obj, type) =>
    Object.keys(obj).find((key) => obj[key] === type);
}

export const generatePricePrintCode = (salesAmount) => {
  if (!salesAmount) {
    salesAmount = 0;
  }

  const salesAmountStr = `${salesAmount}`;
  if (!salesAmountStr.includes(".")) {
    return salesAmountStr.length <= 3
      ? `${salesAmountStr.padStart(3, "0")}0`
      : salesAmountStr.substr(0, 4);
  }

  const amountArr = salesAmountStr.split(".");
  let beforeDot = amountArr[0];
  let afterDot = amountArr[1];
  if (afterDot.length < 2) {
    afterDot = `${afterDot.padEnd(2, "0")}`;
  }

  if (beforeDot.length < 2) {
    beforeDot = `${beforeDot.padStart(2, "0")}`;
  }

  return `${beforeDot}${afterDot}`;
};

export const generateFileName = (id, moduleId) => {
  const suffixNumber = new Date().valueOf();
  return `${id ?? StorageModule.getName(moduleId)}-photo-${suffixNumber}`;
};

export const isMobileOrTablet = () => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export const processInBatches = async (items, batchSize, workerFunction) => {
  const results = [];
  for (let i = 0; i < items.length; i += batchSize) {
    const batch = items.slice(i, i + batchSize);
    const batchResults = await Promise.all(batch.map(workerFunction));
    results.push(...batchResults);
  }
  return results;
};

export const preventNonNumericInput = (e) => {
  const invalidChars = ["e", "E", "+", "-", "."];
  if (invalidChars.includes(e.key)) {
    e.preventDefault();
  }
};
